import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import 'rxjs/Observable';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardService } from './dashboard/dashboard.service';
import { EnrollService } from './enroll-assosiate/enroll-assosiate.service';
import { MatTableModule, MatProgressBarModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule, MatBadgeModule, MatExpansionModule, MatIconModule, MatAutocompleteModule, MatButtonModule, MatProgressSpinnerModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatSlideToggleModule,MatCardModule, MatTabsModule} from '@angular/material';
import { EnrollAssosiateComponent } from './enroll-assosiate/enroll-assosiate.component';
import { UnenrollAssosiateComponent } from './unenroll-assosiate/unenroll-assosiate.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonService } from './service/common.service';
import { ExcelService } from './service/excel.service';
import { ReviewPaymentComponent } from './review-payment/review-payment.component';
import { CorpDashboardComponent } from './corp-dashboard/corp-dashboard.component';
import { BlobModule } from 'angular-azure-blob-service';
import { ReviewPaymentService } from './review-payment/review-payment.service';
import { DebitPaymentService } from './debit-payment/debit-payment.service';
import { CorpHoldResumeComponent } from './corp-hold-resume/corp-hold-resume.component';
import { UnenrollService } from './unenroll-assosiate/unenroll.service';
import { CorpHoldResumeService } from './corp-hold-resume/corp-hold-resume.service';
import { ReportsComponent } from './reports/reports.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from './notification/notification.service'
import { AdminComponentComponent } from './admin-component/admin-component.component';
import { AdminService } from "./admin-component/admin.service";
import { TimeAgoPipe } from 'time-ago-pipe';
import * as Highcharts from 'highcharts';
import * as highmaps from 'highcharts/modules/map.src';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { JoyrideModule } from 'ngx-joyride'
import * as more from 'highcharts/highcharts-more.src';
import stock from 'highcharts/modules/stock.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { ReportService } from './reports/reports.service';
import { AccesscontrolComponent } from './accesscontrol/accesscontrol.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { SettingsComponent } from './settings/settings.component';
import { AccessControlService } from './accesscontrol/accesscontrol.service';
import { AuthGuard } from './auth-guard/auth.guard';
// import { RoleGuard } from './auth-guard/role.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ViewDealershipEnrollmentComponent } from './view-dealership-enrollment/view-dealership-enrollment.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentHistoryService } from './payment-history/payment-history.service';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { CorpDashboardService } from './corp-dashboard/corp-dashboard.service';
import { AdminReportComponent } from './admin-report/admin-report.component';
import { AdminReportService } from './admin-report/admin-report.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SearchAssociateComponent } from './search-associate/search-associate.component';
import { SearchAssociateService } from './search-associate/search-associate.service';
import { PaymentProcessComponent } from './payment-process/payment-process.component';
import { PaymentProcessService } from './payment-process/payment-process.service';
import { EnrollmentTypePipe } from './corp-dashboard/enrollmentType.pipe';
import { TooltipDirective } from './app-tooltip.directive';
import { SwitchAccountComponent } from './switch-account/switch-account.component';
import { SwitchAccountService } from './switch-account/switch-account.service';
import { PaymentReportsComponent } from './payment-reports/payment-reports.component';
import { PaymentReportsService } from './payment-reports/payment-reports.service';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from './auth-guard/auth-config';
import { authModuleConfig } from './auth-guard/auth-module-config';
import { AuthService } from './auth-guard/auth.service';
import {DebitPaymentComponent } from './debit-payment/debit-payment.component';
import {watchlistComponent} from './watchlist/watchlist.component';
import {WatchlistService} from './watchlist/watchlist.service';
import {BillingComponent} from './billing/billing.component';
import {BillingService} from './billing/billing.service';
import { AseLexusComponent } from './ase-lexus/ase-lexus.component';
import { AseCertificationStatusComponent } from './ase-certification-status/ase-certification-status.component';
import { AseLexusService } from './ase-lexus/ase-lexus.service';
import { AseRejectionReportComponent } from './ase-rejection-report/ase-rejection-report.component';
import { AseCollisionReportComponent } from './ase-collision-report/ase-collision-report.component';
import { TurnOverReportsComponent } from './turn-over-reports/turn-over-reports.component';
import { LexusJobComponent } from './lexus-job/lexus-job.component';
import { UserSummaryComponent } from './user-summary/user-summary.component';
import { LexusJobService } from './lexus-job/lexus-job.service';
import { JobEnrollmentHistoryComponent } from './job-enrollment-history/job-enrollment-history.component';

const appRoutes: Routes = [
  {path:'id_token',redirectTo:'/dashboard',pathMatch:'full'},
  { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: 'login', component: LoginComponent,
    data: {
      isLogin: 'Yes'
    }
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Dealer Principal'
    }
  },
  {
    path: 'enroll', component: EnrollAssosiateComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Dealer Principal'
    }
  },
  {
    path: 'holdResumePayments', component: UnenrollAssosiateComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Dealer Principal'
    }
  },
  {
    path: 'holdResumePayments/:id', component: UnenrollAssosiateComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Dealer Principal'
    }
  },
  {
    path: 'paymentReports', component: PaymentReportsComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Dealer Principal'
    }
  },
  {
    path: 'corpDashboard', component: CorpDashboardComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College',
    }
  },{
    path: 'corpDashboard/:id', component: CorpDashboardComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College',
    }
  },
  {
    path: 'reviewPayment', component: ReviewPaymentComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'debitPayment', component: DebitPaymentComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'watchlist', component: watchlistComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  
  {
    path: 'corpHoldResume', component: CorpHoldResumeComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'corpHoldResume/:id', component: CorpHoldResumeComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'corpHoldResume/:term', component: CorpHoldResumeComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'reports', component: ReportsComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'switchAccount', component: SwitchAccountComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Lexus College'
    }
  },
  {
    path: 'admin', component: AdminComponentComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Admin'
    }
  },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },
  {
    path: 'access', component: AccesscontrolComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Admin'
    }
  },
  {
    path: 'searchAssociate', component: SearchAssociateComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Admin'
    }
  },
  {
    path: 'adminReport', component: AdminReportComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Admin'
    }
  },
  {
    path: 'billing', component: BillingComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'ase-lexus', component: AseLexusComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'certification-status', component: AseCertificationStatusComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'lexus-job', component: LexusJobComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'rejection-report', component: AseRejectionReportComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'collision-report', component: AseCollisionReportComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'turn-over-report', component: TurnOverReportsComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'paymentProcess', component: PaymentProcessComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Admin'
    }
  },
  {
    path: 'user-summary', component: UserSummaryComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  {
    path: 'job-enrollment-history', component: JobEnrollmentHistoryComponent, canActivate: [AuthGuard],
    data: {
      expectedRole: 'Billing'
    }
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'dealershipEnrollment', component: ViewDealershipEnrollmentComponent },
  { path: 'paymentHistory', component: PaymentHistoryComponent },
  { path: 'sendNotification', component: SendNotificationComponent },
  // { path: '**', redirectTo: '/admin', canActivate: [ RoleGuard]}
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EnrollAssosiateComponent,
    UnenrollAssosiateComponent,
    ReviewPaymentComponent,
    CorpDashboardComponent,
    CorpHoldResumeComponent,
    ReportsComponent,
    NotificationComponent,
    AdminComponentComponent,
    TimeAgoPipe,
    AccesscontrolComponent,
    LoginComponent,
    SettingsComponent,
    UnauthorizedComponent,
    ViewDealershipEnrollmentComponent,
    PaymentHistoryComponent,
    SendNotificationComponent,
    AdminReportComponent,
    SearchAssociateComponent,
    PaymentProcessComponent,
    EnrollmentTypePipe,
    TooltipDirective,
    SwitchAccountComponent,
    PaymentReportsComponent,
    DebitPaymentComponent,
    watchlistComponent,
    BillingComponent,
    AseLexusComponent,
    AseCertificationStatusComponent,
    AseRejectionReportComponent,
    AseCollisionReportComponent,
    TurnOverReportsComponent,
    LexusJobComponent,
    UserSummaryComponent,
    JobEnrollmentHistoryComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule, MatProgressSpinnerModule, MatBadgeModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatExpansionModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forRoot(appRoutes,{useHash: true,scrollPositionRestoration: 'enabled'}),
    BlobModule.forRoot(),
    JoyrideModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ChartModule,
    OAuthModule.forRoot(),
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTabsModule
  ],
  providers: [
    DashboardService,
    ReviewPaymentComponent,
    CommonService,
    ExcelService,
    EnrollService,
    ReviewPaymentService,
    DebitPaymentService,
    WatchlistService,
    watchlistComponent,
    BillingComponent,
    BillingService,
    AseLexusService,
    // {provide:LocationStrategy,useClass :HashLocationStrategy}, 
    UnenrollService,
    CorpHoldResumeService,
    AdminService,
    AuthGuard,
    // RoleGuard,
    AccessControlService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [stock, more, highmaps] },
    ReportService,
    LoginService,
    LoginComponent,
    NotificationService,
    DashboardComponent,
    AppComponent,
    PaymentHistoryService,
    CorpDashboardService,
    AdminReportService,
    SearchAssociateService,
    PaymentProcessService,
    EnrollmentTypePipe,
    SwitchAccountService,
    SwitchAccountComponent,
    CorpDashboardComponent,
    PaymentReportsService,
    AuthService,
    LexusJobService,
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthModuleConfig, useValue: authModuleConfig },
    { provide: OAuthStorage, useValue: sessionStorage}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
