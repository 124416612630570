import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { Chart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import HighMaps from 'highcharts/highmaps'
Drilldown(Highcharts);
declare var $: any;
import { ReportService } from 'src/app/reports/reports.service';
import { ExcelService } from '../service/excel.service';
declare var require: any;
import { Router } from '@angular/router';

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {

  loadSpinner: boolean;
  userDetail: any;
  dropdown: boolean = false;
  filteredDealers: any[] = [];
  dealerForm: FormGroup;
  isLoading = false;
  dealerCode: any = "";
  userList: any[] = [];
  spinIdClicked: Boolean = false;
  selectedRegion: string = '';
  selectedRegionSt: string = '';
  selectedRegionStAr: string = this.appService.selectedRegion;
  PrimaryRole: string = '';
  IsPC: string = '';
  IsCertJR: string = '';
  vehicleLeaseData: string = '1';
  mapLoading: boolean = true;
  areaWiseData: number = -1;
  areaWiseLists: any = {};
  showAreaMap: boolean = false;
  showStaff: boolean = false;
  showLfs: boolean = false;
  showDealerEnrollmentChart: boolean = false;
  showCertifStats: boolean = false;
  showVehicleLeased: boolean = false;
  expandKey: string;
  showAngularChart: boolean = false;
  dasboardTab: boolean = false;
  areaPayment: number = 0;
  lfsFilterData: string = '';

  //CHART VARIABLES
  chart: any = {};
  topPaidChart: any = {};
  holdResumeChart: any = {};
  dealerEnrollChart: any = {};
  associateEnrollChart: any = {};
  areaWiseChart: any = {};
  buySellChart: any = {};
  staffPaymentsChart: any = {};
  vehiclesleasedchart: any = {};
  jobRollEnrollChart: any = {};
  modalChart: any = {};
  lfsExpireChart: any = {};

  //Trend Chart Variable
  trendEligibleData = [];
  trendNonEligibleData = [];
  trendEnrolledData = [];
  trendPaidData = [];
  trendNewEnrollData = [];
  trendCategory = [];
  trendResponse: any = '';
  trendPaidResponse: any = '';
  downloadTrendResponse: any = [];
  staffDownloadResponse: any = [];

  //Top Paid Chart Variables
  topPaidResponse: any = '';
  topPaidCategory = [];
  previousMonthPaymentCount = [];
  previousToPreviousMonthPaymentCount = [];
  threeMonthBackMonthPaymentCount = [];
  lastMonthPeriod: any = '';
  last2MonthPeriod: any = '';
  last3MonthPeriod: any = '';
  downloadTopPaidResponse: any = [];
  topPaidPeriod = [];
  dealerCodeList = [];

  //Dealer Enrollment Variables
  enrollmentData: any = {};
  enrolledCount: number = 0;
  pendingPrincipalCount: number = 0;
  draftCount: number = 0;
  notEnrolledCount: number = 0;
  enrollemtDrillDown: any[] = [];
  pendingDrillDown: any[] = [];
  dealerEnrollmentResponse: any = '';
  dealerEnrollWithin3month: number = 0;
  dealerEnrollBefore3month: number = 0;
  dealerEnrollPending: number = 0;

  //Certification Stats Variables
  certificationstatResponse: any = '';
  certificationEligibleData: number = 0;
  certificationNonEligibleData: number = 0;
  certificationMasterData: number = 0;
  certificationSeniorData: number = 0;
  certificationMasterJobYes: number = 0;
  certificationMasterJobNo: number = 0;
  certificationSeniorElig: number = 0;
  certificationSeniorNotElig: number = 0;
  certificationCertified: number = 0;
  downloadCertifiStatsResponse: any = '';
  downloadCSJSON: any = [];

  //Associate Enrollment Variable
  AssociateEnrollmentResponse: any = '';
  downloadAEResponse: any = [];
  downloadAEJSONData: any = [];
  previousMonth: any = [0, 0, 0, 0, 0];
  currentMonth: any = [0, 0, 0, 0, 0];
  date = new Date();
  month: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  currentMonthName: any = this.month[this.date.getMonth()] + ' ' + this.date.getFullYear();
  previousMonthName: any = this.month[this.date.getMonth() - 1] + ' ' + this.date.getFullYear();

  //Area wise Variable
  northAreaWisePayment: number = 0;
  southAreaWisePayment: number = 0;
  eastAreaWisePayment: number = 0;
  westAreaWisePayment: number = 0;
  downloadAreaResponse: any = [];


  //Hold\Resume Payment Chart Variable
  holdResumeResponse: any = '';
  holdData: any = [];
  ResumeData: any = [];
  holdResumeCategory: any = [];
  downloadHoldResumeResponse: any = [];
  HRPayJson: any = [];

  //Staff Payment Chart Variable
  staffPaymentResponse: any = '';
  staffPayCategory: any = [];
  one_1: number = 0; one_2: number = 0; one_3: number = 0; one_4: number = 0; one_5: number = 0;
  one_6: number = 0; one_7: number = 0; one_8: number = 0; one_9: number = 0; one_10: number = 0;
  one_11: number = 0; one_12: number = 0; one_13: number = 0; one_14: number = 0; one_15: number = 0;
  one_16: number = 0; one_17: number = 0; one_18: number = 0; one_19: number = 0; one_20: number = 0;
  drillDown_1: any = []; drillDown_2: any = []; drillDown_3: any = []; drillDown_4: any = []; drillDown_5: any = [];
  drillDown_6: any = []; drillDown_7: any = []; drillDown_8: any = []; drillDown_9: any = []; drillDown_10: any = [];
  drillDown_11: any = []; drillDown_12: any = []; drillDown_13: any = []; drillDown_14: any = []; drillDown_15: any = [];
  drillDown_16: any = []; drillDown_17: any = []; drillDown_18: any = []; drillDown_19: any = []; drillDown_20: any = [];
  staffPayData: any = [];
  downloadStaffPayResponse: any = [];
  SPJson: any = [];

  //Lfs Expiry Chart Variable
  lfsExpiryResponse: any = '';
  lfsExpiryCategory: any = [];
  oneLfs_1: number = 0; oneLfs_2: number = 0; oneLfs_3: number = 0; oneLfs_4: number = 0;
  oneLfs_5: number = 0; oneLfs_6: number = 0;
  lfsExpiryData: any = [];
  downloadLfsExpiryResponse: any = [];
  LEJson: any = [];

  //Vehicle Leased chart Variables
  vehicleLeasedResponse: any = "";
  vehicleLeasedData: any = [];
  vehicleLeasedCategory: any = [];
  filterPeriod: any = "";
  mainData: any = [];
  drilldownData: any = [];
  downloadVLResponse: any = [];

  downloadDealerResponse: any = [];

  //Chart Loading Variables
  trendChartLoading: boolean;
  areaChartLoading: boolean;
  topPaidChartLoading: boolean;
  StaffPaymentChartLoading: boolean;
  holdResumePaymentChartLoading: boolean;
  dealerShipChartLoading: boolean;
  associateEnrollmentChartLoading: boolean;
  buySellChartLoading: boolean;
  certificationStatsChartLoading: boolean;
  vehicleChartLoading: boolean;
  jobRoleChartLoading: boolean;
  LfsExpiryChartLoading: boolean;

  //Download Loading Variables
  widgetName: string = '';
  filter: string = '';
  trendDownloadLoading: boolean;
  areaDownloadLoading: boolean;
  topPaidDownloadLoading: boolean;
  StaffPaymentDownloadLoading: boolean;
  holdResumePayDownloadLoading: boolean;
  dealerShipDownloadLoading: boolean;
  associateEnrollmentDownloadLoading: boolean;
  buySellDownloadLoading: boolean;
  certificationStatsDownloadLoading: boolean;
  vehicleDownloadLoading: boolean;
  jobRoleDownloadLoading: boolean;
  LfsExpiryDownloadLoading: boolean;
  StaffReportDownloadLoading: boolean;


  //Buy Sell Report Variables
  buySellReportResponse: any = "";
  terminatedData: any = [];
  newData: any = [];
  transferredData: any = [];
  buySellCategory: any = [];
  transferredDealers: any = [];
  terminatedDealers: any = [];
  newDealers: any = [];
  downloadBuySellResponse: any = [];
  BuySellJson: any = [];

  //No Data Variables
  noBuySellData: boolean = false;
  noAssociateEnrollData: boolean = false;
  noVehicleData: boolean = false;
  noHoldResumeData: boolean = false;
  noCertStatData: boolean = false;
  notrendData: boolean = false;
  nojobRoleData: boolean = false;
  noStaffData: boolean = false;
  notopPaidData: boolean = false;
  noDealerData: boolean = false;
  noAreaData: boolean = false;
  noLfsData: boolean = false;

  //No Data Download Variables
  downloadLoading: boolean = false;
  noBuySellDownloadData: boolean = false;
  noAssociateEnrollDownloadData: boolean = false;
  noVehicleDownloadData: boolean = false;
  noHoldResumeDownloadData: boolean = false;
  noCertStatDownloadData: boolean = false;
  notrendDownloadData: boolean = false;
  nojobRoleDownloadData: boolean = false;
  noStaffDownloadData: boolean = false;
  notopPaidDownloadData: boolean = false;
  noDealerDownloadData: boolean = false;
  noAreaWiseData: boolean = false;
  noLfsDownloadData: boolean = false;
  noStaffDownloadReport: boolean = false;

  //Filter Variables
  dealerFilter: boolean = false;

  //Job role Chart Variable
  jobRoleChartResponse: any = '';
  jobRoleData: any = [];

  screenWidth_768: boolean = false;
  @ViewChild("staffPayChart", { read: ElementRef }) staffPayChart: ElementRef;
  @ViewChild("lfsExpChart", { read: ElementRef }) lfsExpChart: ElementRef;
  @ViewChild("certificationChart", { read: ElementRef }) certificationChart: ElementRef;
  @ViewChild("areaMap", { read: ElementRef }) areaMap: ElementRef;
  @ViewChild("vehiclesleasedHighchart", { read: ElementRef }) vehiclesleasedHighchart: ElementRef;
  @ViewChild("dealerEnrolChart", { read: ElementRef }) dealerEnrolChart: ElementRef;
  @ViewChild("expandAreaChart", { read: ElementRef }) expandAreaChart: ElementRef;
  @ViewChild("expandStaffChart", { read: ElementRef }) expandStaffChart: ElementRef;
  @ViewChild("expandLfsChart", { read: ElementRef }) expandLfsChart: ElementRef;
  @ViewChild("expandcertificationChart", { read: ElementRef }) expandcertificationChart: ElementRef;
  @ViewChild("expandvehicleLeasedChart", { read: ElementRef }) expandvehicleLeasedChart: ElementRef;
  @ViewChild("expandDealerEnrolChart", { read: ElementRef }) expandDealerEnrolChart: ElementRef;
  jobRoleDrillFinal: any = [];
  pdcDate: Date;
  certEligNoDupe: any;
  certNotEligNoDupe: any[];
  certEligArr: any[];
  certNotEligArr: any[];
  certEligBothCount: any;
  certEligMasterCount: number;
  certEligDexYesCount: number;
  downloadEligStat: any[];
  downloadEligStatNoDupe: any[];
  certNoDupe: any[];

  constructor(private fb: FormBuilder, public appService: CommonService, public el: ElementRef,
    public reportService: ReportService, private excelService: ExcelService, private router: Router) {
    $('html,body').animate({ scrollTop: -90 }, "fast");
    // this.loadSpinner = true;
    this.vehicleLeaseData = '1';
    if (this.date.getMonth() == 0) {
      this.previousMonthName = 'DEC ' + (this.date.getFullYear() - 1);
    }
    if (this.appService.isAreaAdmin == true) {
      this.selectedRegion = this.appService.selectedRegion;
      // console.log('Reports Component Check 1 :',this.selectedRegion);
    }
    this.getTrendData();
    this.getTopPaidData();
    this.dealerEnrollmentChartServiceCall();
    this.getCertificationStatsData();
    this.getAssociateEnrollmentData();
    this.getHoldResumePaymentData();
    this.loadAreaWisePaymentList();
    this.getAreaChart(-1);
    this.getStaffPaymentData();
    this.getLfsExpiryData();
    this.getBuySellData();
    this.jobRollChart();
    this.changefilterPeriod('ACTIVE');
    this.getJobDescriptionData();

    if (screen.width == 768) {
      this.screenWidth_768 = true;
    }
  }

  ngOnInit() {
    console.log('clg admin reports',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.dealerForm = this.fb.group({
      userInput: null
    });
  }

  CheckChartLoading() {

    if (!this.trendChartLoading) {
      // this.loadSpinner = false;
      this.trendChart();
    }
    // if (!this.trendChartLoading && !this.topPaidChartLoading && !this.associateEnrollmentChartLoading && !this.vehicleChartLoading && !this.buySellChartLoading && !this.StaffPaymentChartLoading && !this.certificationStatsChartLoading && !this.dealerShipChartLoading && !this.holdResumePaymentChartLoading) {
    //   this.loadSpinner = false;
    //   this.trendChart();
    // }

    // console.log("--------------------------------------------");
    // console.log('Trend', this.trendChartLoading);
    // console.log('Area', this.areaChartLoading);
    // console.log('Top', this.topPaidChartLoading);
    // console.log('vL', this.vehicleChartLoading);
    // console.log('AEnroll', this.associateEnrollmentChartLoading);
    // console.log('SP', this.StaffPaymentChartLoading);
    // console.log('Buy', this.buySellChartLoading);
    // console.log('Certi', this.certificationStatsChartLoading);
    // console.log('Dealership', this.dealerShipChartLoading);
    // console.log('HoldRes', this.holdResumePaymentChartLoading);
    // console.log("--------------------------------------------");
  }

  // =====================================RESET  FUNCTION============================================
  reset() {
    this.dealerCode = "";
    this.selectedRegion = "";
    this.changeAreaCode("");
    this.dealerFilter = false
  }

  resetDealer() {
    this.dealerCode = "";
    this.selectedRegion = this.appService.selectedRegion;
    this.dealerFilter = false;
    this.getTrendData();
    this.getAssociateEnrollmentData();
    this.getCertificationStatsData();
    this.changefilterPeriod('ACTIVE');
    this.getBuySellData();
    this.getStaffPaymentData();
    this.getLfsExpiryData();
    this.getTopPaidData();
    this.getJobDescriptionData();
    this.getAreaChart(-1);
    this.getHoldResumePaymentData();
    this.dealerEnrollmentChartServiceCall();
  }

  changeAreaCode(code) {
    console.log('Code', code);
    this.selectedRegion = code;
    this.dealerCode = "";
    if (this.selectedRegion == "") {
      this.dealerFilter = false;
    } else {
      this.dealerFilter = true;
    }
    // this.loadSpinner = true;
    this.getTrendData();
    this.getAssociateEnrollmentData();
    this.getCertificationStatsData();
    this.changefilterPeriod('ACTIVE');
    this.getBuySellData();
    this.getStaffPaymentData();
    this.getLfsExpiryData();
    this.getTopPaidData();
    this.getJobDescriptionData();
    this.getAreaChart(-1);
    this.getHoldResumePaymentData();
    this.dealerEnrollmentChartServiceCall();
  }

  // ======================================DEALER CODE SEARCH==========================================

  fadeDiv() {
    $('#ChartSection').fadeTo('slow', .4);
    this.spinIdClicked = true;
    this.dealerFilter = false;
  }

  fadeOut() {
    $('#ChartSection').fadeTo('slow', 1);
    this.spinIdClicked = false;
  }

  closeSpinSearch() {
    $('#ChartSection').fadeTo('slow', 1);
    this.dealerCode = "";
    this.spinIdClicked = true;
  }

  filterDealerCode() {
    this.dealerCode = this.dealerCode.replace(/[^a-zA-Z0-9 ]/g, "");
    if (this.dealerCode.length > 1 && this.dealerCode != undefined) {
      this.filteredDealers = [];
      this.isLoading = true;
      //console.log(this.dealerCode, this.selectedRegion);
      this.reportService.getDealerCodeSearch(this.dealerCode, this.selectedRegion).subscribe((result: any) => {
        this.filteredDealers = result.DealerCodeSearch.Result;
        // console.log(this.filteredDealers);
        for (let i = 0; i < this.filteredDealers.length; i++) {
          this.filteredDealers[i].AccountName = this.filteredDealers[i].Account_Name;
          this.filteredDealers[i].dealerCodeNo = this.filteredDealers[i].dealerCode;
        }
        this.isLoading = false;
        // console.log('Search', this.filteredDealers);
      });
    }
  }

  selectDealer(dealer) {
    if (dealer.length == 5) {
      this.dealerCode = dealer;
      this.dealerFilter = true;
      // this.loadSpinner = true;
      this.getTrendData();
      this.changefilterPeriod('ACTIVE');
      this.getAssociateEnrollmentData();
      this.getBuySellData();
      this.getCertificationStatsData();
      this.getStaffPaymentData();
      this.getLfsExpiryData();
      this.getHoldResumePaymentData();
      this.getTopPaidData();
      this.getJobDescriptionData();
      this.getAreaChart(-1);
      this.dealerEnrollmentChartServiceCall();
    }
  }

  // =========================================TREND CHART==========================================

  getTrendData() {
    this.trendChartLoading = true;
    this.trendResponse = "";
    this.trendPaidResponse = "";
    this.trendEligibleData = [];
    this.trendNonEligibleData = [];
    this.trendEnrolledData = [];
    this.trendNewEnrollData = [];
    this.trendCategory = [];
    this.trendPaidData = [];
    this.reportService.getTrendData(this.dealerCode.trim(), this.selectedRegion.trim()).subscribe((response: any) => {
      // console.log("TREND Count", response.TrendData.Result);
      this.trendResponse = response.TrendData.Result;
      this.reportService.getTrendPaidData(this.dealerCode.trim(), this.selectedRegion.trim()).subscribe((response: any) => {
        // console.log("Trend Paid Count Response", response.TrendPaidCount.Result);
        this.trendPaidResponse = response.TrendPaidCount.Result;

        this.trendChartLoading = false;
        this.CheckChartLoading();

        var noDataCheck = 0;
        for (let k = 0; k < this.trendPaidResponse.length; k++) {
          if (this.trendPaidResponse[k].paidCount == 0) {
            noDataCheck += 0;
          } else {
            noDataCheck += 1;
          }
        }
        (this.trendResponse.length == 0 && noDataCheck == 0) ? this.notrendData = true : this.notrendData = false;
        for (let i = this.trendPaidResponse.length - 1; i >= 0; i--) {
          if (this.trendCategory.indexOf(this.trendPaidResponse[i].month) == -1) {
            this.trendCategory.push(this.trendPaidResponse[i].month);
            this.trendPaidData.push(this.trendPaidResponse[i].paidCount);
            this.trendEligibleData.push(0);
            this.trendNonEligibleData.push(0);
            this.trendEnrolledData.push(0);
            this.trendNewEnrollData.push(0);
          }
        }

        for (let i = 0; i < this.trendResponse.length; i++) {
          let counteligible = 0;
          let countnoneligible = 0;
          let countenroll = 0;
          let countnewEnroll = 0;
          let months = [];
          if (months.indexOf(this.trendResponse[i].period) == -1) {
            months.push(this.trendResponse[i].period);
            counteligible += this.trendResponse[i].eligibleCount;
            countnoneligible += this.trendResponse[i].notEligibleCount;
            countenroll += this.trendResponse[i].enrolledCount;
            countnewEnroll += this.trendResponse[i].newlyEnrolledCount;

            for (let j = 0; j < this.trendResponse.length; j++) {
              if (j != i && this.trendResponse[i].period == this.trendResponse[j].period) {
                counteligible += this.trendResponse[j].eligibleCount;
                countnoneligible += this.trendResponse[j].notEligibleCount;
                countenroll += this.trendResponse[j].enrolledCount;
                countnewEnroll += this.trendResponse[j].newlyEnrolledCount;
              }
            }

            var period = this.trendCategory.indexOf(this.trendResponse[i].period);
            this.trendEligibleData[period] = (counteligible);
            this.trendNonEligibleData[period] = (-countnoneligible);
            this.trendEnrolledData[period] = (countenroll);
            this.trendNewEnrollData[period] = (countnewEnroll);
          }
        }
        // console.log('CATEGORY', this.trendCategory);
        // console.log('Eligible', this.trendEligibleData);
        // console.log('Not Elig', this.trendNonEligibleData);
        // console.log('Enroll', this.trendEnrolledData);
        // console.log('Paid', this.trendPaidData);
        // console.log('New Enroll', this.trendNewEnrollData);
        this.trendChart();
      }, error => {
        this.trendPaidResponse = "";
        this.trendPaidData = [];
        this.trendCategory = [];
        console.log('Error While fetching Trend Paid Count Data');
        this.notrendData = true;
        this.trendChartLoading = false;
        this.CheckChartLoading();
        console.log('Error', error);
      });
    }, error => {
      this.trendResponse = '';
      this.trendEligibleData = [];
      this.trendNonEligibleData = [];
      this.trendEnrolledData = [];
      this.trendPaidData = [];
      this.trendNewEnrollData = [];
      this.trendCategory = [];
      this.trendChartLoading = false;
      this.notrendData = true;
      this.CheckChartLoading();
      this.trendChart();
      console.log('Error While fetching Trend Chart Data');
      console.log('Error', error);
    });
  }

  downloadTrendReport() {
    this.downloadTrendResponse = [];
    this.trendDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.downloadTrendData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadTrendResponse = response.DownloadTrendReport.Result;
      this.downloadTrendResponse.length == 0 ? this.notrendDownloadData = true : this.notrendDownloadData = false;
      this.trendDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.trendDownloadLoading && !this.notrendDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadTrendResponse, 'Trend');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.trendDownloadLoading = false;
      this.downloadLoading = false;
      this.downloadTrendResponse = [];
      this.closeLoadingModal();
      this.notrendDownloadData = true;
      this.showDownloadErrorModal();
      console.log('Error while fetching Data for Trend Download Data');
      console.log('error', err);
    });
  }

  trendChart() {
    this.chart = new Chart({
      chart: {
        type: 'column',
        marginLeft: 50,
        marginRight: 70,
      },
      title: {
        align: 'left',
        text: 'TREND',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 30
      },
      subtitle: {
        align: 'left',
        text: 'Count of associates eligible, enrolled, newly enrolled and paid every month',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.series.name + '</b><br/>'
            + this.point.category + ' : <b>' + Highcharts.numberFormat(Math.abs(this.point.y), 0) + '</b>';
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: true
            }
          },
          borderColor: 'black',
          pointWidth: 10,
        }
      },
      xAxis: {
        categories: this.trendCategory,
        crosshair: true
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        tickInterval: 2,
        tickLength: 1,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#C19237'
          },
        }
      }, { // Secondary yAxis
        tickLength: 1,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: 'grey'
          },
          formatter: function () {
            var value = Math.abs(this.value);
            return (value).toString();
          }
        },
        opposite: true
      }],
      legend: {
        symbolRadius: 0,
        borderColor: 'black',
      },
      series: [{
      //   type: 'column',
      //   name: 'NOT ELIGIBLE',
      //   color: '#d8d9da',
      //   yAxis: 1,
      //   data: this.trendNonEligibleData
      // }, {
      //   type: 'column',
      //   name: 'ELIGIBLE',
      //   color: 'darkgrey',
      //   yAxis: 1,
      //   data: this.trendEligibleData
      // }, {
      //   type: 'column',
      //   name: 'ENROLLED',
      //   color: 'black',
      //   yAxis: 1,
      //   data: this.trendEnrolledData
      // }, {
        type: 'column',
        name: 'PAID',
        color: '#c19237',
        yAxis: 1,
        data: this.trendPaidData
      }, {
        type: 'line',
        name: 'NEWLY ENROLLED',
        color: '#C19237',
        yAxis: 0,
        data: this.trendNewEnrollData
      }]
    });
  }

  // ====================================TOP PAID CHART============================================
  getTopPaidData() {
    this.topPaidChartLoading = true;
    this.topPaidResponse = '';
    this.topPaidCategory = [];
    this.previousMonthPaymentCount = [];
    this.threeMonthBackMonthPaymentCount = [];
    this.previousToPreviousMonthPaymentCount = [];
    this.dealerCodeList = [];
    var lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    this.lastMonthPeriod = this.toshortformart(lastMonth);
    var last2Month = new Date();
    last2Month.setDate(1);
    last2Month.setMonth(last2Month.getMonth() - 2);
    this.last2MonthPeriod = this.toshortformart(last2Month);
    var last3Month = new Date();
    last3Month.setDate(1);
    last3Month.setMonth(last3Month.getMonth() - 3);
    this.last3MonthPeriod = this.toshortformart(last3Month);
    this.reportService.getTopPaid(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.topPaidResponse = response.TopPaidDealer.Result;
      // console.log('topPaidResponse', this.topPaidResponse);
      if (this.topPaidResponse.length == 0) {
        this.notopPaidData = true;
        this.topPaidChartLoading = false;
      } else {
        this.notopPaidData = false;
        for (let i = 0; i < this.topPaidResponse.length; i++) {
          this.topPaidCategory.push(this.topPaidResponse[i].dealerName);
          this.dealerCodeList.push(this.topPaidResponse[i].dealerCode);
        }
        this.topPaidSummary();
      }
    }, err => {
      console.log('Error', err);
      console.log('Error while getting data for Top paid dealers');
      this.notopPaidData = true;
      this.topPaidChartLoading = false;
      this.CheckChartLoading();
    });
  }

  async topPaidSummary() {
    for (let i = 0; i < this.dealerCodeList.length; i++) {
      await this.paidService(i);
    }
  }

  async paidService(index) {
    await this.reportService.getTopPaidSummary(this.dealerCodeList[index]).toPromise().then(async (response: any) => {
      // console.log('Top Paid', response.TopPaidDealer.Result);
      if (response.TopPaidDealer.Result != undefined && response.TopPaidDealer.Result.length != 0) {
        this.previousMonthPaymentCount.push(response.TopPaidDealer.Result[0].lastMonth);
        this.previousToPreviousMonthPaymentCount.push(response.TopPaidDealer.Result[1].last2Month);
        this.threeMonthBackMonthPaymentCount.push(response.TopPaidDealer.Result[2].last3Month);
      } else {
        this.previousMonthPaymentCount.push(0);
        this.previousToPreviousMonthPaymentCount.push(0);
        this.threeMonthBackMonthPaymentCount.push(0);
      }
      if (index == this.dealerCodeList.length - 1) {
        this.topPaidDealerShipChart();
        this.topPaidChartLoading = false;
        this.CheckChartLoading();
      }
    }, err => {
      console.log('Error', err);
      console.log('error while fetching data for top paid summary');
      this.previousMonthPaymentCount.push(0);
      this.previousToPreviousMonthPaymentCount.push(0);
      this.threeMonthBackMonthPaymentCount.push(0);
    });
  }

  downloadTPReport() {
    if (this.dealerCodeList.length != 0) {
      this.downloadTopPaidResponse = [];
      this.topPaidDownloadLoading = true;
      this.downloadLoading = true;
      var dListStr = '';
      for (let i = 0; i < this.dealerCodeList.length; i++) {
        if (i == this.dealerCodeList.length - 1) {
          dListStr += "'" + this.dealerCodeList[i] + "'";
        } else {
          dListStr += "'" + this.dealerCodeList[i] + "',";
        }
      }
      this.reportService.downloadTopPaidData(dListStr).subscribe((response: any) => {
        // console.log('Top Paid', response.TopPaidDealer.Result);
        this.downloadTopPaidResponse = response.TopPaidDealer.Result;
        this.downloadTopPaidResponse.length == 0 ? this.notopPaidDownloadData = true : this.notopPaidDownloadData = false;
        this.topPaidDownloadLoading = false;
        this.downloadLoading = false;
        this.closeLoadingModal();
        if (!this.topPaidDownloadLoading && !this.notopPaidDownloadData) {
          this.excelService.exportAsExcelFile(this.downloadTopPaidResponse, 'Top Paid Dealership');
        } else {
          this.showDownloadErrorModal();
        }
      }, err => {
        this.topPaidDownloadLoading = false;
        this.downloadLoading = false;
        this.downloadTopPaidResponse = [];
        this.showDownloadErrorModal();
        console.log('Error while fetching Data for Top Paid Dealership Download Data');
        console.log('error', err);
      });
    } else {
      this.closeLoadingModal();
      this.showDownloadErrorModal();
    }
  }

  toshortformart(date) {
    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];

    var day = date.getDate();
    var month_index = date.getMonth();
    var year = date.getFullYear();

    return month_names[month_index] + "-" + year;
  }

  topPaidDealerShipChart() {
    this.topPaidChart = new Chart({
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'TOP PAID DEALERSHIPS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        margin: 50,
      },
      subtitle: {
        text: 'Top paid dealership of last three month',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          animation: {
            duration: 1000
          },
          states: {
            hover: {
              enabled: true
            }
          },
        },
      },
      xAxis: {
        categories: this.topPaidCategory,
      },
      yAxis: {
        min: 0,
        tickInterval: 2,
        tickLength: 1,
        title: {
          text: '',
        },
        labels: {
          style: {
            color: 'grey'
          },
          formatter: function () {
            return this.value;
          }
        },
        opposite: true
      },
      legend: {
        symbolRadius: 0,
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: this.last3MonthPeriod,
          color: 'black',
          data: this.threeMonthBackMonthPaymentCount

        },
        {
          name: this.last2MonthPeriod,
          color: 'grey',
          data: this.previousToPreviousMonthPaymentCount

        },
        {
          name: this.lastMonthPeriod,
          color: 'lightgrey',
          data: this.previousMonthPaymentCount
        },]
    });
  }

  //  ==============================HOLD RESUME PAYMENT CHART=======================================

  getHoldResumePaymentData() {
    this.holdResumePaymentChartLoading = true;
    this.holdResumeResponse = [];
    this.holdResumeCategory = [];
    this.holdData = [];
    this.ResumeData = [];
    this.reportService.getHoldResumeData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      // console.log('Hold Resume Payment Data', response.HoldResumeList.Result);
      this.holdResumeResponse = response.HoldResumeList.Result;

      this.holdResumeResponse.length == 0 ? this.noHoldResumeData = true : this.noHoldResumeData = false;
      this.holdResumePaymentChartLoading = false;
      this.CheckChartLoading();

      let count = 0, hold = 0, resume = 0, category = [];
      for (let j = 0; j < this.holdResumeResponse.length; j++) {
        if (category.indexOf(this.holdResumeResponse[j].HR_month) == -1) {
          category.push(this.holdResumeResponse[j].HR_month);
        }
      }

      for (let j = 0; j < category.length; j++) {
        this.holdData.push(0);
        this.ResumeData.push(0);
      }

      for (let j = 0; j < this.holdResumeResponse.length; j++) {
        if (this.holdResumeCategory.indexOf(this.holdResumeResponse[j].HR_month) == -1) {
          this.holdResumeCategory.push(this.holdResumeResponse[j].HR_month);
          if (this.holdResumeResponse[j].isResumed == 'F') {
            hold = this.holdResumeResponse[j].no_hold_count;
          } else {
            resume = (this.holdResumeResponse[j].no_hold_count);
          }
          for (let i = 0; i < this.holdResumeResponse.length; i++) {
            if (i != j && this.holdResumeResponse[j].HR_month == this.holdResumeResponse[i].HR_month) {
              if (this.holdResumeResponse[i].isResumed != 'F') {
                resume = (this.holdResumeResponse[i].no_hold_count);
              } else {
                hold = this.holdResumeResponse[i].no_hold_count;
              }
            }
          }
          this.holdData[count] = hold;
          this.ResumeData[count] = resume;
          hold = 0;
          resume = 0;
          count += 1
        }
      };

      // console.log('Hold Data', this.holdData);
      // console.log('Resume Data', this.ResumeData);
      // console.log('HoldResume Month', this.holdResumeCategory);
      this.onHoldResumeChart();
    }, error => {
      this.holdData = [];
      this.ResumeData = [];
      this.holdResumeCategory = [];
      this.holdResumePaymentChartLoading = false;
      this.noHoldResumeData = false;
      this.CheckChartLoading();
      console.log('Error While fetching Data for Hold Resume Payment Chart');
      console.log('Error =>', error);
    });
  }

  downloadHRReport() {
    this.downloadLoading = true;
    this.holdResumePayDownloadLoading = true;
    this.downloadHoldResumeResponse = [];
    this.reportService.downloadHoldResumeData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      // console.log('Hold Resume Payment Download Data', response.HoldResumeList.Result);
      this.downloadHoldResumeResponse = response.HoldResumeList.Result;
      if (this.downloadHoldResumeResponse.length == 0) {
        this.noHoldResumeDownloadData = true
      } else {
        this.noHoldResumeDownloadData = false;
      }
      this.holdResumePayDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.holdResumePayDownloadLoading && !this.noHoldResumeDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadHoldResumeResponse, 'On-Hold\\Resume Payment');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.closeLoadingModal();
      this.downloadLoading = false;
      this.downloadHoldResumeResponse = [];
      this.holdResumePayDownloadLoading = false;
      this.showDownloadErrorModal();
      console.log('Error while fetching Data for Hold Resume Download Function');
      console.log('Error', err);
    });
  }

  onHoldResumeChart() {
    this.holdResumeChart = new Chart({
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'ON-HOLD/RESUMED PAYMENTS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'No. of associate put on hold and resumed',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: true
            }
          },
          pointWidth: 13,
        }
      },
      xAxis: {
        categories: this.holdResumeCategory
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: ''
        },
        opposite: true
      },
      legend: {
        symbolRadius: 0
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'ONHOLD',
          color: '#d8d9da',
          data: this.holdData

        }, {
          name: 'RESUMED',
          color: 'black',
          data: this.ResumeData

        }]
    });
  }

  // ====================================DEALER ENROLLMENT CHART==============================  
  dealerEnrollmentChartServiceCall() {
    this.dealerShipChartLoading = true;
    this.dealerEnrollBefore3month = 0;
    this.dealerEnrollWithin3month = 0;
    this.dealerEnrollPending = 0;
    this.enrolledCount = 0;
    this.pendingPrincipalCount = 0;
    this.draftCount = 0;
    this.notEnrolledCount = 0;
    this.noDealerData = false;
    this.reportService.getDealerEnrollment(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      console.log("DEALER ENROLLMENT ", response.DealerEnrollment.Result);
      this.dealerEnrollmentResponse = response.DealerEnrollment.Result;
      console.log(this.dealerEnrollmentResponse);
      if (this.dealerEnrollmentResponse.length == 0) {
        this.enrollemtDrillDown = [];
        this.noDealerData = true;
      } else {
        this.enrollemtDrillDown = [];
        this.enrolledCount = 0;
        this.pendingPrincipalCount = 0;
        this.draftCount = 0;
        this.notEnrolledCount = 0;
        for (const i of this.dealerEnrollmentResponse) {
          if (i.enrollment_status === 'ENROLLED') {
            this.enrolledCount += i.NoOfRecords;
            var arr = [];
            arr.push(i.Period);
            arr.push(i.NoOfRecords);
            this.enrollemtDrillDown.push(arr);
            console.log("enrolled count: ", this.enrolledCount, typeof(this.enrolledCount));
          } else if (i.enrollment_status === 'PENDING WITH PRINCIPAL') {
            this.pendingPrincipalCount += i.NoOfRecords;
            console.log("in progress count: ", this.pendingPrincipalCount, typeof(this.pendingPrincipalCount));
          }
          else if (i.enrollment_status === 'DRAFT') {
            this.draftCount += i.NoOfRecords;
            console.log("draft count: ", this.draftCount, typeof(this.draftCount));
          }
          else if (i.enrollment_status === "NOT ENROLLED") {
            this.notEnrolledCount += i.NoOfRecords;
            console.log("not enrolled count: ", this.notEnrolledCount, typeof(this.notEnrolledCount));
          }
        }
      }

      console.log(this.enrollemtDrillDown + '\n' + this.pendingDrillDown);

      this.enrollmentData = [
        {
          name: "Enrolled",
          y: this.enrolledCount,
          color: 'black',
          drilldown: "enrolled"
        },
        {
          name: "Enrollment In Progress",
          y: this.pendingPrincipalCount,
          color: '#C19237'
        },
        {
          name: "Draft",
          y:this.draftCount,
          color: 'grey'
        },
        {
          name: "Not Enrolled",
          y:this.notEnrolledCount,
          color: '#C0C0C0'
        }
      ]
      this.dealerShipChartLoading = false;
      this.CheckChartLoading();
      this.dealerEnrollmentChart();
    }, error => {
      this.trendEligibleData = [];
      this.noDealerData = true;
      this.dealerShipChartLoading = false;
      this.CheckChartLoading();
      console.log('Error While fetching Trend Chart Data');
      console.log('Error', error);
    });
  }

  downloadDEReport() {
    this.downloadDealerResponse = [];
    this.dealerShipDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.DownloadDealerEnrollment(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadDealerResponse = response.DealerShipDetails.Result;
      this.downloadDealerResponse.length == 0 ? this.noDealerDownloadData = true : this.noDealerDownloadData = false;
      this.dealerShipDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.dealerShipDownloadLoading && !this.noDealerDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadDealerResponse, 'DealerShip Enrollment');
      } else {
        this.showDownloadErrorModal();
      }

    }, error => {
      this.closeLoadingModal();
      this.downloadLoading = false;
      this.downloadDealerResponse = [];
      this.showDownloadErrorModal();
      this.dealerShipDownloadLoading = false;
      console.log('Error While fetching Dealership Download Data');
      console.log('Error', error);
    });
  }


  dealerEnrollmentChart() {
    Highcharts.chart(this.dealerEnrolChart.nativeElement, {
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 300
      },
      title: {
        text: 'DEALERSHIP  ENROLLMENT',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
      },
      subtitle: {
        text: 'Number of dealerships enrolled in the program',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        symbolRadius: 0,
        verticalAlign: 'bottom',
        labelFormatter: function () {
          return this.name;
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:12px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        },
        pie: {
          startAngle: 70,
          endAngle: 70,
          center: ['50%', '50%'],
          size: '70%',
          innerSize: '60%',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            color: 'grey',
            formatter: function () {
              return this.y;
            },
          }
        }
      },
      series: [{
        name: 'Dealership Enrollment',
        data: this.enrollmentData
      }],
      drilldown: {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: "bold",
          textDecoration: 'underline',
        },
        series: [
          {
            name: 'Enrolled',
            id: "enrolled",
            data: this.enrollemtDrillDown
          }
        ]
      }
    });

    Highcharts.chart(this.expandDealerEnrolChart.nativeElement, {
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 300
      },
      title: {
        text: 'DEALERSHIP  ENROLLMENT',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
      },
      subtitle: {
        text: 'Number of dealerships enrolled in the program',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        symbolRadius: 0,
        verticalAlign: 'bottom',
        labelFormatter: function () {
          return this.name;
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:12px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        },
        pie: {
          startAngle: 70,
          endAngle: 70,
          center: ['50%', '50%'],
          size: '70%',
          innerSize: '60%',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            color: 'grey',
            formatter: function () {
              return this.y;
            },
          }
        }
      },
      series: [{
        name: 'Dealership Enrollment',
        data: this.enrollmentData
      }],
      drilldown: {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: "bold",
          textDecoration: 'underline',
        },
        series: [
          {
            name: 'Enrolled',
            id: "enrolled",
            data: this.enrollemtDrillDown
          }
        ]
      }
    });
  }

  // ====================================ASSOCIATE ENROLLMENT CHART===============================
  getAssociateEnrollmentData() {
    this.associateEnrollmentChartLoading = true;
    this.AssociateEnrollmentResponse = '';
    this.previousMonth = [0, 0, 0, 0, 0];
    this.currentMonth = [0, 0, 0, 0, 0];
    this.reportService.getAssociateEnrollmentData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      // console.log('Associate Response', response.AssociateEnrollment.Result);
      this.AssociateEnrollmentResponse = response.AssociateEnrollment.Result;
      this.associateEnrollmentChartLoading = false;
      this.CheckChartLoading();

      if (this.AssociateEnrollmentResponse.length != 0) {
        for (let i = 0; i < this.AssociateEnrollmentResponse.length; i++) {
          if (this.AssociateEnrollmentResponse[i].period == 'previousMonth') {
            if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'DRAFT') {
              this.previousMonth[0] = -this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING UPLOAD') {
              this.previousMonth[1] = -this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING WITH ASSOCIATE') {
              this.previousMonth[2] = -this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING WITH PRINCIPAL') {
              this.previousMonth[3] = -this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'ENROLLED') {
              this.previousMonth[4] = -this.AssociateEnrollmentResponse[i].count;
            }
          } else {
            if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'DRAFT') {
              this.currentMonth[0] = this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING UPLOAD') {
              this.currentMonth[1] = this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING WITH ASSOCIATE') {
              this.currentMonth[2] = this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'PENDING WITH PRINCIPAL') {
              this.currentMonth[3] = this.AssociateEnrollmentResponse[i].count;
            } else if (this.AssociateEnrollmentResponse[i].enrollmentStatus.toUpperCase() == 'ENROLLED') {
              this.currentMonth[4] = this.AssociateEnrollmentResponse[i].count;
            }
          }
        }
        this.noAssociateEnrollData = false;
        // console.log('prev', this.previousMonth);
        // console.log('Current', this.currentMonth);
      } else {
        this.noAssociateEnrollData = true;
        this.previousMonth = [0, 0, 0, 0, 0];
        this.currentMonth = [0, 0, 0, 0, 0];
      }

      this.associateEnrollmentChart();
    }, err => {
      this.previousMonth = [0, 0, 0, 0, 0];
      this.currentMonth = [0, 0, 0, 0, 0];
      this.noAssociateEnrollData = true;
      this.associateEnrollmentChartLoading = false;
      this.CheckChartLoading();
      console.log('Error while fetching data for Associate Enrollment Chart');
      console.log('Error', err);
    });
  }

  downloadAEReport() {
    //Download Excel Service Call
    this.associateEnrollmentDownloadLoading = true;
    this.downloadAEResponse = [];
    this.downloadAEJSONData = [];
    var PrepaCount = 0, PreppCount = 0, PrePuCount = 0, PredraftCount = 0, PreenrollCount = 0, dlr = [];
    var CurpaCount = 0, CurppCount = 0, CurPuCount = 0, CurdraftCount = 0, CurenrollCount = 0;
    var prevoiusMonthObj = {}, CurrentMonthObj = {};
    this.reportService.downloadAssociateEnrollmentData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadAEResponse = response.AssociateEnrollment.Result;
      // console.log(this.downloadAEResponse);
      this.associateEnrollmentDownloadLoading = false;
      if (this.downloadAEResponse.length == 0) {
        this.noAssociateEnrollDownloadData = true;
      } else {
        this.noAssociateEnrollDownloadData = false;
        for (let i = 0; i < this.downloadAEResponse.length; i++) {
          PrepaCount = 0; PreppCount = 0; PrePuCount = 0; PredraftCount = 0; PreenrollCount = 0;
          CurpaCount = 0; CurppCount = 0; CurPuCount = 0; CurdraftCount = 0; CurenrollCount = 0;
          if (dlr.indexOf(this.downloadAEResponse[i].dealer_code) == -1) {
            dlr.push(this.downloadAEResponse[i].dealer_code);
            if (this.downloadAEResponse[i].period == 'previousMonth') {
              if (this.downloadAEResponse[i].enrollmentStatus == 'DRAFT') {
                PredraftCount = this.downloadAEResponse[i].count;
              } else if (this.downloadAEResponse[i].enrollmentStatus == 'PENDING UPLOAD') {
                PrePuCount = this.downloadAEResponse[i].count;
              } else if (this.downloadAEResponse[i].enrollmentStatus == 'PENDING WITH ASSOCIATE') {
                PrepaCount = this.downloadAEResponse[i].count;
              } else if (this.downloadAEResponse[i].enrollmentStatus == 'PENDING WITH PRINCIPAL') {
                PreppCount = this.downloadAEResponse[i].count;
              } else if (this.downloadAEResponse[i].enrollmentStatus == 'ENROLLED') {
                PreenrollCount = this.downloadAEResponse[i].count;
              }
            }
            for (let j = 0; j < this.downloadAEResponse.length; j++) {
              if (i != j && i < j) {
                if (this.downloadAEResponse[i].dealer_code == this.downloadAEResponse[j].dealer_code) {
                  if (this.downloadAEResponse[i].period == this.downloadAEResponse[j].period) {
                    if (this.downloadAEResponse[j].enrollmentStatus == 'DRAFT') {
                      PredraftCount = this.downloadAEResponse[j].count;;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING UPLOAD') {
                      PrePuCount = this.downloadAEResponse[j].count;;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING WITH ASSOCIATE') {
                      PrepaCount = this.downloadAEResponse[j].count;;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING WITH PRINCIPAL') {
                      PreppCount = this.downloadAEResponse[j].count;;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'ENROLLED') {
                      PreenrollCount = this.downloadAEResponse[j].count;;
                    }
                  } else {
                    if (this.downloadAEResponse[j].enrollmentStatus == 'DRAFT') {
                      CurdraftCount = this.downloadAEResponse[j].count;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING UPLOAD') {
                      CurPuCount = this.downloadAEResponse[j].count;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING WITH ASSOCIATE') {
                      CurpaCount = this.downloadAEResponse[j].count;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'PENDING WITH PRINCIPAL') {
                      CurppCount = this.downloadAEResponse[j].count;
                    } else if (this.downloadAEResponse[j].enrollmentStatus == 'ENROLLED') {
                      CurenrollCount = this.downloadAEResponse[j].count;
                    }
                  }
                }
              }
            }
            prevoiusMonthObj = {
              "Dealer_Code": this.downloadAEResponse[i].dealer_code,
              "Area": this.downloadAEResponse[i].Region,
              "Period": this.previousMonthName,
              "Enrolled": PreenrollCount,
              "Pending_Principal": PreppCount,
              "Pending_Associate": PrepaCount,
              "Pending_Upload": PrePuCount,
              "Drafts": PredraftCount
            }
            // console.log('prevoiusMonthObj', prevoiusMonthObj);

            CurrentMonthObj = {
              "Dealer_Code": this.downloadAEResponse[i].dealer_code,
              "Area": this.downloadAEResponse[i].Region,
              "Period": this.currentMonthName,
              "Enrolled": CurenrollCount,
              "Pending_Principal": CurppCount,
              "Pending_Associate": CurpaCount,
              "Pending_Upload": CurPuCount,
              "Drafts": CurdraftCount
            }

            // console.log('CurrentMonthObj', CurrentMonthObj);

            this.downloadAEJSONData.push(prevoiusMonthObj);
            this.downloadAEJSONData.push(CurrentMonthObj);

            // console.log('Download Ae', this.downloadAEJSONData);
          }
        }
      }
      this.downloadLoading = false;
      this.associateEnrollmentDownloadLoading = false;
      this.closeLoadingModal();
      // this.downloadAEJSONData = this.downloadAEJSONData.filter(function (elem, index, self) { return index === self.indexOf(elem); })
      if (!this.associateEnrollmentDownloadLoading && !this.noAssociateEnrollDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadAEJSONData, 'Associate Enrollment');
      } else {
        // this.noAssociateEnrollDownloadData = true;
        this.showDownloadErrorModal();
      }
    }, err => {
      this.closeLoadingModal();
      this.associateEnrollmentDownloadLoading = false;
      this.downloadAEResponse = [];
      this.showDownloadErrorModal();
      console.log('Some Error occured while fetching data for Associate Enrollment Excel Download Report');
      console.log('Error =>', err);
    });
  }


  associateEnrollmentChart() {
    var categories = [
      'DRAFTS', 'PENDING UPLOAD', 'PENDING WITH ASSOCIATE', 'PENDING WITH PRINCIPAL', 'ENROLLED'
    ];

    this.associateEnrollChart = new Chart({
      chart: {
        type: 'bar',
        height: 300
      },
      title: {
        text: 'ASSOCIATE ENROLLMENTS - STATUS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'View enrollments created in previous and current month',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        reversed: false,
        labels: {
          step: 1
        },
        opposite: true,
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value) + '';
          }
        },
        tickInterval: 2,
      },
      legend: {
        symbolRadius: 0
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },

      tooltip: {
        formatter: function () {
          return '<b>' + this.series.name + ' ' + this.point.category + '</b><br/>' +
            'Associate Enrollment Status: <b>' + Highcharts.numberFormat(Math.abs(this.point.y), 0) + '</b>';
        }
      },

      series: [{
        name: this.previousMonthName,
        color: 'grey',
        data: this.previousMonth
      }, {
        name: this.currentMonthName,
        color: 'black',
        data: this.currentMonth
      }]
    });
  }

  //=====================================STAFF PAYMENT CHART==================================
  getStaffPaymentData() {
    this.StaffPaymentChartLoading = true;
    this.staffPaymentResponse = '';
    this.one_1 = 0; this.one_2 = 0; this.one_3 = 0; this.one_4 = 0; this.one_5 = 0; this.one_6 = 0; this.one_7 = 0; this.one_8 = 0; this.one_9 = 0; this.one_10 = 0;
    this.one_11 = 0; this.one_12 = 0; this.one_13 = 0; this.one_14 = 0; this.one_15 = 0; this.one_16 = 0; this.one_17 = 0; this.one_18 = 0; this.one_19 = 0; this.one_20 = 0;
    this.drillDown_1 = []; this.drillDown_2 = []; this.drillDown_3 = []; this.drillDown_4 = []; this.drillDown_5 = [];
    this.drillDown_6 = []; this.drillDown_7 = []; this.drillDown_8 = []; this.drillDown_9 = []; this.drillDown_10 = [];
    this.drillDown_11 = []; this.drillDown_12 = []; this.drillDown_13 = []; this.drillDown_14 = []; this.drillDown_15 = [];
    this.drillDown_16 = []; this.drillDown_17 = []; this.drillDown_18 = []; this.drillDown_19 = []; this.drillDown_20 = [];

    this.reportService.getStaffPaymentData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.staffPaymentResponse = response.StaffPaymentList.Result;

      this.StaffPaymentChartLoading = false;
      this.CheckChartLoading();
      // console.log('SP', this.staffPaymentResponse);
      if (this.staffPaymentResponse.length == 0) {
        this.noStaffData = true;
      } else {
        this.noStaffData = false;
      }

      for (let i = 0; i < this.staffPaymentResponse.length; i++) {
        if (this.staffPaymentResponse[i].typerec == 'superset' && this.staffPaymentResponse[i].range1 != null) {
          if (this.staffPaymentResponse[i].range1 == '1-12') {
            this.one_1 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '13-24') {
            this.one_2 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '25-36') {
            this.one_3 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '37-48') {
            this.one_4 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '49-60') {
            this.one_5 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '61-72') {
            this.one_6 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '72-84') {
            this.one_7 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '85-96') {
            this.one_8 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '97-108') {
            this.one_9 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '109-120') {
            this.one_10 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '121-132') {
            this.one_11 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '133-144') {
            this.one_12 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '145-156') {
            this.one_13 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '157-168') {
            this.one_14 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '169-180') {
            this.one_15 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '181-192') {
            this.one_16 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '193-204') {
            this.one_17 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '205-216') {
            this.one_18 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '217-228') {
            this.one_19 = this.staffPaymentResponse[i].recCnt;
          } else if (this.staffPaymentResponse[i].range1 == '>229') {
            this.one_20 = this.staffPaymentResponse[i].recCnt;
          }
        } else if (this.staffPaymentResponse[i].typerec == 'subset' && this.staffPaymentResponse[i].range1 != null) {
          let drillData = [this.staffPaymentResponse[i].range1, this.staffPaymentResponse[i].recCnt];
          if (parseInt(this.staffPaymentResponse[i].range1) > 0 && parseInt(this.staffPaymentResponse[i].range1) <= 12) {
            this.drillDown_1.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 12 && parseInt(this.staffPaymentResponse[i].range1) <= 24) {
            this.drillDown_2.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 24 && parseInt(this.staffPaymentResponse[i].range1) <= 36) {
            this.drillDown_3.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 36 && parseInt(this.staffPaymentResponse[i].range1) <= 48) {
            this.drillDown_4.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 48 && parseInt(this.staffPaymentResponse[i].range1) <= 60) {
            this.drillDown_5.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 60 && parseInt(this.staffPaymentResponse[i].range1) <= 72) {
            this.drillDown_6.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 72 && parseInt(this.staffPaymentResponse[i].range1) <= 84) {
            this.drillDown_7.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 84 && parseInt(this.staffPaymentResponse[i].range1) <= 96) {
            this.drillDown_8.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 96 && parseInt(this.staffPaymentResponse[i].range1) <= 108) {
            this.drillDown_9.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 108 && parseInt(this.staffPaymentResponse[i].range1) <= 120) {
            this.drillDown_10.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 120 && parseInt(this.staffPaymentResponse[i].range1) <= 132) {
            this.drillDown_11.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 132 && parseInt(this.staffPaymentResponse[i].range1) <= 144) {
            this.drillDown_12.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 144 && parseInt(this.staffPaymentResponse[i].range1) <= 156) {
            this.drillDown_13.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 156 && parseInt(this.staffPaymentResponse[i].range1) <= 168) {
            this.drillDown_14.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 168 && parseInt(this.staffPaymentResponse[i].range1) <= 180) {
            this.drillDown_15.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 180 && parseInt(this.staffPaymentResponse[i].range1) <= 192) {
            this.drillDown_16.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 192 && parseInt(this.staffPaymentResponse[i].range1) <= 204) {
            this.drillDown_17.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 204 && parseInt(this.staffPaymentResponse[i].range1) <= 216) {
            this.drillDown_18.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 216 && parseInt(this.staffPaymentResponse[i].range1) <= 228) {
            this.drillDown_19.push(drillData);
          } else if (parseInt(this.staffPaymentResponse[i].range1) > 228) {
            this.drillDown_20.push(drillData);
          }
        }
      };
      this.staffPayData = [
        {
          name: "1-12",
          y: this.one_1,
          drilldown: "12"
        },
        {
          name: "13-24",
          y: this.one_2,
          drilldown: "24"
        },
        {
          name: "25-36",
          y: this.one_3,
          drilldown: "36"
        },
        {
          name: "37-48",
          y: this.one_4,
          drilldown: "48"
        },
        {
          name: "49-60",
          y: this.one_5,
          drilldown: "60"
        },
        {
          name: "61-72",
          y: this.one_6,
          drilldown: "72"
        },
        {
          name: "73-84",
          y: this.one_7,
          drilldown: "84"
        },
        {
          name: "85-96",
          y: this.one_8,
          drilldown: "96"
        },
        {
          name: "97-108",
          y: this.one_9,
          drilldown: "108"
        },
        {
          name: "109-120",
          y: this.one_10,
          drilldown: "120"
        },
        {
          name: "121-132",
          y: this.one_11,
          drilldown: "132"
        },
        {
          name: "133-144",
          y: this.one_12,
          drilldown: "144"
        },
        {
          name: "145-156",
          y: this.one_13,
          drilldown: "156"
        },
        {
          name: "157-168",
          y: this.one_14,
          drilldown: "168"
        },
        {
          name: "169-180",
          y: this.one_15,
          drilldown: "180"
        },
        {
          name: "181-192",
          y: this.one_16,
          drilldown: "192"
        },
        {
          name: "193-204",
          y: this.one_17,
          drilldown: "204"
        },
        {
          name: "205-216",
          y: this.one_18,
          drilldown: "216"
        },
        {
          name: "217-228",
          y: this.one_19,
          drilldown: "228"
        },
        {
          name: ">229",
          y: this.one_20,
          drilldown: "240"
        }
      ];
      this.staffPaymentChart();

      // console.log('this.staffPayData', this.staffPayData)
    }, error => {
      this.staffPaymentResponse = '';
      this.noStaffData = true;
      this.one_1 = 0; this.one_2 = 0; this.one_3 = 0; this.one_4 = 0; this.one_5 = 0; this.one_6 = 0; this.one_7 = 0; this.one_8 = 0; this.one_9 = 0; this.one_10 = 0;
      this.one_11 = 0; this.one_12 = 0; this.one_13 = 0; this.one_14 = 0; this.one_15 = 0; this.one_16 = 0; this.one_17 = 0; this.one_18 = 0; this.one_19 = 0; this.one_20 = 0;
      this.drillDown_1 = []; this.drillDown_2 = []; this.drillDown_3 = []; this.drillDown_4 = []; this.drillDown_5 = [];
      this.drillDown_6 = []; this.drillDown_7 = []; this.drillDown_8 = []; this.drillDown_9 = []; this.drillDown_10 = [];
      this.drillDown_11 = []; this.drillDown_12 = []; this.drillDown_13 = []; this.drillDown_14 = []; this.drillDown_15 = [];
      this.drillDown_16 = []; this.drillDown_17 = []; this.drillDown_18 = []; this.drillDown_19 = []; this.drillDown_20 = [];
      this.StaffPaymentChartLoading = false;
      this.CheckChartLoading();
      this.staffPaymentChart();
      console.log('Some Error occured while fetching data for Staff Payment Report Data');
      console.log('Error =>', error);
    });
  }


  downloadSPReport() {
    this.downloadStaffPayResponse = [];
    this.StaffPaymentDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.downloadStaffPaymentData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadStaffPayResponse = response.StaffPaymentList.Result;
      console.log("this.downloadStaffPayResponse", this.downloadStaffPayResponse);
      this.downloadStaffPayResponse.length == 0 ? this.noStaffDownloadData = true : this.noStaffDownloadData = false;
      for (let i = 0; i < this.downloadStaffPayResponse.length; i++) {
        if (this.downloadStaffPayResponse[i].PaymentCount != null) {
          this.SPJson.push(this.downloadStaffPayResponse[i]);
        }
      }
      console.log("this.SPJson", this.SPJson);
      this.StaffPaymentDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.StaffPaymentDownloadLoading && !this.noStaffDownloadData) {
        this.excelService.exportAsExcelFile(this.SPJson, 'Staff Payment');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.StaffPaymentDownloadLoading = false;
      this.downloadLoading = false;
      this.downloadStaffPayResponse = [];
      this.noStaffDownloadData = false;
      this.closeLoadingModal();
      this.showDownloadErrorModal();
      console.log('Error while fetching Data for Staff Payment Download Data');
      console.log('error', err);
    });
  }


  staffPaymentChart() {
    Highcharts.chart(this.staffPayChart.nativeElement, {
      chart: {
        type: 'column',
        height: 370
      },
      title: {
        text: 'ASSOCIATE PAYMENTS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'Number of associates receiving multiple payments',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        type: 'category',
        title: {
          text: 'No. of Payments',
        }
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
        title: {
          text: null
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'center',
            style: {
              color: 'black',
            }
          }
        },
        column: {
          maxPointWidth: 100,
          showInLegend: false
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b> of total<br/>'
      },

      series: [
        {
          name: "No. of Payments",
          color: '#d8d9da',
          data: this.staffPayData
        }
      ],
      drilldown: {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: null,
          textDecoration: 'none',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        series: [
          {
            name: "No.of Payments: 1-12",
            id: "12",
            data: this.drillDown_1
          },
          {
            name: "No.of Payments: 13-24",
            id: "24",
            data: this.drillDown_2
          },
          {
            name: "No.of Payments: 25-36",
            id: "36",
            data: this.drillDown_3
          },
          {
            name: "No.of Payments: 37-48",
            id: "48",
            data: this.drillDown_4
          },
          {
            name: "No.of Payments: 49-60",
            id: "60",
            data: this.drillDown_5
          },
          {
            name: "No.of Payments: 61-72",
            id: "72",
            data: this.drillDown_6
          },
          {
            name: "No.of Payments: 73-84",
            id: "84",
            data: this.drillDown_7
          },
          {
            name: "No.of Payments: 85-96",
            id: "96",
            data: this.drillDown_8
          },
          {
            name: "No.of Payments: 97-108",
            id: "108",
            data: this.drillDown_9
          },
          {
            name: "No.of Payments: 109-120",
            id: "120",
            data: this.drillDown_10
          },
          {
            name: "No.of Payments: 121-132",
            id: "132",
            data: this.drillDown_11
          },
          {
            name: "No.of Payments: 133-144",
            id: "144",
            data: this.drillDown_12
          },
          {
            name: "No.of Payments: 145-156",
            id: "156",
            data: this.drillDown_13
          },
          {
            name: "No.of Payments: 157-168",
            id: "168",
            data: this.drillDown_14
          },
          {
            name: "No.of Payments: 169-180",
            id: "180",
            data: this.drillDown_15
          },
          {
            name: "No.of Payments: 181-192",
            id: "192",
            data: this.drillDown_16
          },
          {
            name: "No.of Payments: 193-204",
            id: "204",
            data: this.drillDown_17
          },
          {
            name: "No.of Payments: 205-216",
            id: "216",
            data: this.drillDown_18
          },
          {
            name: "No.of Payments: 217-228",
            id: "228",
            data: this.drillDown_19
          },
          {
            name: "No.of Payments: >229",
            id: "240",
            data: this.drillDown_20
          }
        ]
      }
    });

    this.staffPaymentsChart = Highcharts.chart(this.expandStaffChart.nativeElement, {
      chart: {
        type: 'column',
        height: 400
      },
      title: {
        text: 'ASSOCIATE PAYMENTS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'Number of associates receiving multiple payments',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
        title: {
          text: null
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'left',
            style: {
              color: 'black',
            }
          }
        },
        column: {
          maxPointWidth: 100,
          showInLegend: false
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b> of Total no. of Payment<br/>'
      },

      series: [
        {
          name: "No. of Payments",
          color: '#d8d9da',
          data: this.staffPayData
        }
      ],
      drilldown: {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: null,
          textDecoration: 'none',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        series: [
          {
            name: "No.of Payments: 1-12",
            id: "12",
            data: this.drillDown_1
          },
          {
            name: "No.of Payments: 13-24",
            id: "24",
            data: this.drillDown_2
          },
          {
            name: "No.of Payments: 25-36",
            id: "36",
            data: this.drillDown_3
          },
          {
            name: "No.of Payments: 37-48",
            id: "48",
            data: this.drillDown_4
          },
          {
            name: "No.of Payments: 49-60",
            id: "60",
            data: this.drillDown_5
          },
          {
            name: "No.of Payments: 61-72",
            id: "72",
            data: this.drillDown_6
          },
          {
            name: "No.of Payments: 73-84",
            id: "84",
            data: this.drillDown_7
          },
          {
            name: "No.of Payments: 85-96",
            id: "96",
            data: this.drillDown_8
          },
          {
            name: "No.of Payments: 97-108",
            id: "108",
            data: this.drillDown_9
          },
          {
            name: "No.of Payments: 109-120",
            id: "120",
            data: this.drillDown_10
          },
          {
            name: "No.of Payments: 121-132",
            id: "132",
            data: this.drillDown_11
          },
          {
            name: "No.of Payments: 133-144",
            id: "144",
            data: this.drillDown_12
          },
          {
            name: "No.of Payments: 145-156",
            id: "156",
            data: this.drillDown_13
          },
          {
            name: "No.of Payments: 157-168",
            id: "168",
            data: this.drillDown_14
          },
          {
            name: "No.of Payments: 169-180",
            id: "180",
            data: this.drillDown_15
          },
          {
            name: "No.of Payments: 181-192",
            id: "192",
            data: this.drillDown_16
          },
          {
            name: "No.of Payments: 193-204",
            id: "204",
            data: this.drillDown_17
          },
          {
            name: "No.of Payments: 205-216",
            id: "216",
            data: this.drillDown_18
          },
          {
            name: "No.of Payments: 217-228",
            id: "228",
            data: this.drillDown_19
          },
          {
            name: "No.of Payments: >229",
            id: "240",
            data: this.drillDown_20
          }
        ]
      }
    })
  }

  //=====================================LFS EXPIRY CHART==================================
  getLfsExpiryData() {
    this.LfsExpiryChartLoading = true;
    this.lfsExpiryData = [];
    this.lfsFilterData = '';
    this.lfsExpiryCategory = ["Expired (Last 6 Months)", "Expired (Last 2 Months)", "Expires in 1 Month", "Expires in 3 Months", "Expires in 6 Months", "Expiry > 6 Months"];
    this.lfsExpiryResponse = '';
    this.oneLfs_1 = 0; this.oneLfs_2 = 0; this.oneLfs_3 = 0; this.oneLfs_4 = 0; this.oneLfs_5 = 0; this.oneLfs_6 = 0;
    this.reportService.getLfsExpiryReportData("", this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.lfsExpiryResponse = response.ValidLFSDetailwithAssoicateReport.Result;

      this.LfsExpiryChartLoading = false;
      this.CheckChartLoading();
      // console.log('LE', this.lfsExpiryResponse);
      if (this.lfsExpiryResponse.length == 0) {
        this.noLfsData = true;
      } else {
        this.noLfsData = false;
      }

      for (let i = 0; i < this.lfsExpiryResponse.length; i++) {
        if (this.lfsExpiryResponse[i].expires_in != null) {
          if (parseInt(this.lfsExpiryResponse[i].expires_in) <= 0 && parseInt(this.lfsExpiryResponse[i].expires_in) >= -180) {
            this.oneLfs_1 += 1;
          } else if (parseInt(this.lfsExpiryResponse[i].expires_in) <= 0 && parseInt(this.lfsExpiryResponse[i].expires_in) >= -60) {
            this.oneLfs_2 += 1;
          } else if (parseInt(this.lfsExpiryResponse[i].expires_in) > 0 && parseInt(this.lfsExpiryResponse[i].expires_in) <= 30) {
            this.oneLfs_3 += 1;
          } else if (parseInt(this.lfsExpiryResponse[i].expires_in) > 0 && parseInt(this.lfsExpiryResponse[i].expires_in) <= 90) {
            this.oneLfs_4 += 1;
          } else if (parseInt(this.lfsExpiryResponse[i].expires_in) > 0 && parseInt(this.lfsExpiryResponse[i].expires_in) <= 180) {
            this.oneLfs_5 += 1;
          } else if (parseInt(this.lfsExpiryResponse[i].expires_in) > 180) {
            this.oneLfs_6 += 1;
          }
        }
      };
      this.lfsExpiryData.push(this.oneLfs_1);
      this.lfsExpiryData.push(this.oneLfs_2);
      this.lfsExpiryData.push(this.oneLfs_3);
      this.lfsExpiryData.push(this.oneLfs_4);
      this.lfsExpiryData.push(this.oneLfs_5);
      this.lfsExpiryData.push(this.oneLfs_6);

      this.lfsExpiryChart();

      // console.log('this.lfsExpiryData', this.lfsExpiryData)
      // console.log('this.lfsExpiryCategory', this.lfsExpiryCategory)

    }, error => {
      this.lfsExpiryResponse = '';
      this.lfsFilterData = '';
      this.lfsExpiryCategory = [];
      this.lfsExpiryData = [];
      this.noLfsData = true;
      this.oneLfs_1 = 0; this.oneLfs_2 = 0; this.oneLfs_3 = 0; this.oneLfs_4 = 0; this.oneLfs_5 = 0; this.oneLfs_6 = 0;
      this.LfsExpiryChartLoading = false;
      this.CheckChartLoading();
      this.lfsExpiryChart();
      console.log('Some Error occured while fetching data for Lfs Expiry Report Data');
      console.log('Error =>', error);
    });
  }


  downloadLEReport() {
    this.downloadLfsExpiryResponse = [];
    this.LEJson = [];
    this.LfsExpiryDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.getLfsExpiryReportData(this.lfsFilterData, this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadLfsExpiryResponse = response.ValidLFSDetailwithAssoicateReport.Result;
      console.log("this.downloadLfsExpiryResponse", this.downloadLfsExpiryResponse);
      this.downloadLfsExpiryResponse.length == 0 ? this.noLfsDownloadData = true : this.noLfsDownloadData = false;
      for (let i = 0; i < this.downloadLfsExpiryResponse.length; i++) {
        if (this.downloadLfsExpiryResponse[i].expires_in != null) {
          this.LEJson.push(this.downloadLfsExpiryResponse[i]);
        }
      }
      console.log("this.LEJson", this.LEJson);
      this.LfsExpiryDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.LfsExpiryDownloadLoading && !this.noLfsDownloadData) {
        this.excelService.exportAsExcelFile(this.LEJson, 'Lfs Expiry');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.LfsExpiryDownloadLoading = false;
      this.downloadLoading = false;
      this.lfsFilterData = '';
      this.downloadLfsExpiryResponse = [];
      this.noLfsDownloadData = false;
      this.closeLoadingModal();
      this.showDownloadErrorModal();
      console.log('Error while fetching Data for Lfs Expiry Download Data');
      console.log('error', err);
    });
  }


  lfsExpiryChart() {
    this.lfsExpireChart = new Chart({
      chart: {
        type: 'column',
        height: 370
      },
      title: {
        text: 'LFS EXPIRY',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'Number of Leases with Expiry Period',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: true
            }
          },
          pointWidth: 13,
        }
      },
      xAxis: {
        categories: this.lfsExpiryCategory
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: ''
        },
        opposite: true
      },
      legend: {
        symbolRadius: 0
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Lease Count",
          color: '#C19237',
          data: this.lfsExpiryData
        }
      ]
    });
  }

  //  ===================================AREA WISE CHART======================================

  loadAreaWisePaymentList() {
    var lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    this.lastMonthPeriod = this.toshortformart(lastMonth);
    var last2Month = new Date();
    last2Month.setDate(1);
    last2Month.setMonth(last2Month.getMonth() - 2);
    this.last2MonthPeriod = this.toshortformart(last2Month);
    var last3Month = new Date();
    last3Month.setDate(1);
    last3Month.setMonth(last3Month.getMonth() - 3);
    this.last3MonthPeriod = this.toshortformart(last3Month);
    this.areaWiseLists = [
      {
        value: -1,
        label: this.lastMonthPeriod
      },
      {
        value: -2,
        label: this.last2MonthPeriod
      },
      {
        value: -3,
        label: this.last3MonthPeriod
      }

    ];
  }

  getAreaChart(month) {
    this.areaChartLoading = true;
    if (month != '') {
      this.areaWiseData = month;
    }
    else {
      this.areaWiseData = -1;
    }
    this.eastAreaWisePayment = 0;
    this.northAreaWisePayment = 0;
    this.southAreaWisePayment = 0;
    this.westAreaWisePayment = 0;
    this.reportService.getAreaWisePaymentData(this.areaWiseData, this.dealerCode, this.selectedRegion).subscribe((response: any) => {

      if (response.AreaWisePayment.Result.length == 0) {
        this.noAreaData = true
      } else {
        // if (response.AreaWisePayment.Result[0].westAreaPayment == 0 && response.AreaWisePayment.Result[1].northAreaPayment == 0 && response.AreaWisePayment.Result[2].eastAreaPayment == 0 && response.AreaWisePayment.Result[3].southAreaPayment == 0) {
        //   this.noAreaData = true
        // } else {
          this.noAreaData = false;
          // this.westAreaWisePayment = response.AreaWisePayment.Result[0].westAreaPayment;
          // this.northAreaWisePayment = response.AreaWisePayment.Result[1].northAreaPayment;
          // this.eastAreaWisePayment = response.AreaWisePayment.Result[2].eastAreaPayment;
          // this.southAreaWisePayment = response.AreaWisePayment.Result[3].southAreaPayment;
          for (let p=0; p<response.AreaWisePayment.Result.length; p++) {
            if (response.AreaWisePayment.Result[p].westAreaPayment) {
              this.westAreaWisePayment = response.AreaWisePayment.Result[p].westAreaPayment;
            } else if (response.AreaWisePayment.Result[p].northAreaPayment) {
              this.northAreaWisePayment = response.AreaWisePayment.Result[p].northAreaPayment;
            } else if (response.AreaWisePayment.Result[p].eastAreaPayment) {
              this.eastAreaWisePayment = response.AreaWisePayment.Result[p].eastAreaPayment;
            } else if (response.AreaWisePayment.Result[p].southAreaPayment) {
              this.southAreaWisePayment = response.AreaWisePayment.Result[p].southAreaPayment;
            }
            var pcd =  response.AreaWisePayment.Result[p].PAYDUECD;
          }
          console.log('west: ',this.westAreaWisePayment,'; north: ',this.northAreaWisePayment, '; east: ',this.eastAreaWisePayment, '; south: ',this.southAreaWisePayment);
          console.log('pcd', pcd);
          this.pdcDate = new Date (pcd.concat('-01').replace(/-/g, '\/')); console.log('pcd date',this.pdcDate);
        // }
      }
      this.areaChartLoading = false;
      this.CheckChartLoading();
      this.areaChart();
    }, error => {
      this.westAreaWisePayment = 0;
      this.northAreaWisePayment = 0;
      this.southAreaWisePayment = 0;
      this.westAreaWisePayment = 0;
      this.areaChartLoading = false;
      this.CheckChartLoading();
      console.log('Error', error);
    });
  };

  downloadAWReport() {
    this.areaDownloadLoading = true;
    this.downloadLoading = true;
    this.downloadAreaResponse = [];
    this.reportService.downloadAreaWisePaymentData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadAreaResponse = response.AreaWiseDetails.Result;
      this.downloadAreaResponse.length == 0 ? this.noAreaWiseData = true : this.noAreaWiseData = false;
      this.areaDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.areaDownloadLoading && !this.noAreaWiseData) {
        this.excelService.exportAsExcelFile(this.downloadAreaResponse, 'Area Wise Payment');
      } else {
        this.showDownloadErrorModal();
      }
    }, error => {
      this.closeLoadingModal();
      this.areaDownloadLoading = false;
      this.downloadLoading = false;
      this.showDownloadErrorModal();
      this.downloadAreaResponse = [];
      console.log('Error', error);
    });
  }

  areaChart() {
    var amt; console.log( 'greater or not',this.pdcDate,new Date ('2023/10/01'),this.pdcDate >= new Date ('2023/10/01'))
    if (this.pdcDate >= new Date ('2023/10/01')) { amt = 300 }
    else { amt = 200 }
    this.mapLoading = true;
    let Highcharts = require('highcharts/highmaps.js'),
      map = require('@highcharts/map-collection/countries/us/custom/us-all-mainland.geo.json');

    var mapDataNew = [{
      "abbrev": "NY",
      "parentState": "East Zone",
      "capital": "Albany",
      "lat": 37.540700,
      "lon": -77.433654,
      "payment": this.eastAreaWisePayment * amt,
      "z": this.eastAreaWisePayment * amt
    }, {
      "abbrev": "CA",
      "parentState": "West Zone",
      "capital": "Sacramento",
      "lat": 38.579065,
      "lon": -121.491014,
      "payment": this.westAreaWisePayment * amt,
      "z": this.westAreaWisePayment * amt
    }, {
      "abbrev": "TX",
      "parentState": "South Zone",
      "capital": "Austin",
      "lat": 30.267605,
      "lon": -97.742984,
      "payment": this.southAreaWisePayment * amt,
      "z": this.southAreaWisePayment * amt
    }, {
      "abbrev": "MN",
      "parentState": "Central Zone",
      "capital": "Saint Paul",
      "lat": 44.943829,
      "lon": -93.093326,
      "payment": this.northAreaWisePayment * amt,
      "z": this.northAreaWisePayment * amt
    }];

    this.areaPayment = 0;
    for (let i = 0; i < mapDataNew.length; i++) {
      this.areaPayment += mapDataNew[i].payment;
    };

    this.mapLoading = false;

    Highcharts.mapChart(this.areaMap.nativeElement, {
      chart: {
        type: 'map',
        height: 300,
        width: 315,
      },

      title: {
        text: 'AREA PAYMENTS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 60
      },

      subtitle: {
        text: 'Amount paid to area',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },

      tooltip: {
        formatter: function () {
          return '<b>' + this.point.parentState + '</b><br/>' +
            "Payment: <b>$" + this.point.payment + "</b>";
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Basemap',
        mapData: map,
        borderColor: 'white',
        nullColor: '#d8d9da',
        showInLegend: false
      }, {
        type: 'mapbubble',
        dataLabels: {
          enabled: true,
          format: '$' + '{point.payment:,.0f}',
          y: 15,
          style: {
            color: 'black',
          }
        },
        data: mapDataNew,
        maxSize: '12%',
        color: 'goldenrod'
      }]
    });

    Highcharts.mapChart(this.expandAreaChart.nativeElement, {
      chart: {
        type: 'map',
        height: 400
      },

      title: {
        text: 'AREA PAYMENTS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 40
      },

      subtitle: {
        text: 'Amount paid to area',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },

      tooltip: {
        formatter: function () {
          return '<b>' + this.point.parentState + '</b><br/>' +
            'Payment: $ <b>' + this.point.payment + ' </b>';
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Basemap',
        mapData: map,
        borderColor: 'white',
        nullColor: '#d8d9da',
        showInLegend: false
      }, {
        type: 'mapbubble',
        dataLabels: {
          enabled: true,
          format: '$' + '{point.payment:,.0f}',
          y: 20,
          style: {
            color: 'black',
          }
        },
        data: mapDataNew,
        maxSize: '12%',
        color: 'goldenrod'
      }]
    });
  }

  // ====================================BUY SELL REPORT CHART===============================

  getBuySellData() {
    this.buySellChartLoading = true;
    this.buySellReportResponse = '';
    this.newData = [];
    this.terminatedData = [];
    this.transferredData = [];
    this.transferredDealers = [];
    this.terminatedDealers = [];
    this.newDealers = [];
    this.buySellCategory = [];
    this.reportService.getBuySellReportData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      // console.log('BUY Sell', response.BuySellData.Result);
      this.buySellReportResponse = response.BuySellData.Result;
      if (this.buySellReportResponse.length == 0) {
        this.noBuySellData = true;
      } else {
        this.noBuySellData = false;
      }
      this.buySellChartLoading = false;
      this.CheckChartLoading();
      var count = 0, newDealers = [], transferedDealers = [], terminatedDealers = [], transferredStr;

      let category = [];
      for (let i = 0; i < this.buySellReportResponse.length; i++) {
        if (category.indexOf(this.buySellReportResponse[i].Period) == -1) {
          category.push(this.buySellReportResponse[i].Period);
          this.terminatedData.push(0);
          this.transferredData.push(0);
          this.newData.push(0);
        }
      };

      for (let i = 0; i < this.buySellReportResponse.length; i++) {
        if (this.buySellCategory.indexOf(this.buySellReportResponse[i].Period) == -1) {
          this.buySellCategory.push(this.buySellReportResponse[i].Period);
          if (this.buySellReportResponse[i].TypeofDlr == 'Terminated') {
            terminatedDealers.push(this.buySellReportResponse[i].dealerCode);
          } else if (this.buySellReportResponse[i].TypeofDlr == 'transferred') {
            transferredStr = ' (' + this.buySellReportResponse[i].Old_dealerCode + ') --> ' + this.buySellReportResponse[i].dealerCode;
            transferedDealers.push(transferredStr);
          } else {
            newDealers.push(this.buySellReportResponse[i].dealerCode);
          }
          for (let j = 0; j < this.buySellReportResponse.length; j++) {
            if (i != j && this.buySellReportResponse[i].Period == this.buySellReportResponse[j].Period) {
              if (this.buySellReportResponse[j].TypeofDlr == 'Terminated') {
                terminatedDealers.push(this.buySellReportResponse[j].dealerCode);
              } else if (this.buySellReportResponse[j].TypeofDlr == 'transferred') {
                transferredStr = ' (' + this.buySellReportResponse[j].Old_dealerCode + ') --> ' + this.buySellReportResponse[j].dealerCode;
                transferedDealers.push(transferredStr);
              } else {
                newDealers.push(this.buySellReportResponse[j].dealerCode);
              }
            }
          }
          // console.log('beforCount', count)
          this.newData[count] = newDealers.length;
          this.transferredData[count] = transferedDealers.length;
          this.terminatedData[count] = terminatedDealers.length;
          this.transferredDealers[count] = transferedDealers;
          this.terminatedDealers[count] = terminatedDealers;
          this.newDealers[count] = newDealers;
          terminatedDealers = [];
          transferedDealers = [];
          newDealers = [];
          count += 1;
          // console.log('afterCount', count)
        }
      }
      // console.log('trans', this.transferredData, this.transferredDealers);
      // console.log('Term', this.terminatedData, this.terminatedDealers);
      // console.log('New', this.newData, this.newDealers);
      // console.log('Categ', this.buySellCategory)

      this.buySellReportChart();
    }, error => {
      this.buySellCategory = [];
      this.buySellReportResponse = '';
      this.noBuySellData = true;
      this.newData = [];
      this.terminatedData = [];
      this.transferredData = [];
      this.transferredDealers = [];
      this.terminatedDealers = [];
      this.newDealers = [];
      this.buySellChartLoading = false;
      this.CheckChartLoading();
      this.buySellReportChart();
      console.log('Error while fetching data for Buy Sell Report Chart');
      console.log('Error', error);
    });
  }

  downloadBSReport() {
    //Excel Download Service Call
    this.downloadBuySellResponse = [];
    this.BuySellJson = [];
    this.buySellDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.downloadBuySellReportData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadBuySellResponse = response.BuySellData.Result;
      this.downloadBuySellResponse.length == 0 ? this.noBuySellDownloadData = true : this.noBuySellDownloadData = false;

      let status = '', termDate = '';
      for (let i = 0; i < this.downloadBuySellResponse.length; i++) {
        if (this.downloadBuySellResponse[i].Term_Date == '01-01-0001') {
          status = 'Active';
          termDate = '';
        } else {
          status = 'Terminated';
          termDate = this.downloadBuySellResponse[i].Term_Date;
        }
        let bsObj = {
          "Dealer Code": this.downloadBuySellResponse[i].DealerCode,
          "Area Code": this.downloadBuySellResponse[i].area,
          "New Dealer": this.downloadBuySellResponse[i].New_Dealer,
          "Old Dealer": this.downloadBuySellResponse[i].Old_Dealer,
          "Start Date": this.downloadBuySellResponse[i].Start_Date,
          "Termination Date": termDate,
          "Status": status
        }

        this.BuySellJson.push(bsObj);
      }
      this.downloadLoading = false;
      this.buySellDownloadLoading = false;
      this.closeLoadingModal();
      if (!this.buySellDownloadLoading && !this.noBuySellDownloadData) {
        this.excelService.exportAsExcelFile(this.BuySellJson, 'Buy Sell Report');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.closeLoadingModal();
      this.downloadBuySellResponse = [];
      this.BuySellJson = [];
      this.noBuySellDownloadData = true;
      this.buySellDownloadLoading = false;
      this.showDownloadErrorModal();
      console.log('Error while fetching data for Buy Sell Report Download Data');
      console.log('Error', err);
    });
  }


  buySellReportChart() {
    var transfer = this.transferredDealers;
    var terminated = this.terminatedDealers;
    var newD = this.newDealers;
    var categories = this.buySellCategory;

    this.buySellChart = new Chart({
      chart: {
        type: 'spline',
        height: 300
      },
      title: {
        text: 'BUY/SELL REPORT',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 60
      },
      legend: {
        symbolRadius: 0
      },

      subtitle: {
        text: 'Stats of new, transferred and terminated dealerships',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.buySellCategory
      },
      yAxis: {
        title: {
          text: ''
        },
        min: 0,
        tickInterval: 2,
        opposite: true,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          var DealerArray;
          if (this.series.name == 'NEW DEALERSHIPS') {
            var index = categories.indexOf(this.point.category);

            var array = newD[index];

            if (array.length == 0) {
              DealerArray = ['-'];
            } else {
              DealerArray = array;
            };

            var newtooltip = '<b>' + this.series.name + ' : '
              + this.point.y + '</b><br><span>New Dealers :</span><br>' + DealerArray;

            return newtooltip;

          } else if (this.series.name == 'TERMINATED DEALERSHIPS') {
            var index = categories.indexOf(this.point.category);
            var array = terminated[index];

            if (array.length == 0) {
              DealerArray = ['-'];
            } else {
              DealerArray = array;
            };
            var Terminatedtooltip = '<b>' + this.series.name + ' : '
              + this.point.y + '</b><br><span>Terminated Dealers :</span><br>' + DealerArray;

            return Terminatedtooltip;

          } else {
            var index = categories.indexOf(this.point.category);
            var array = transfer[index];

            if (array.length == 0) {
              DealerArray = ['-'];
            } else {
              DealerArray = array;
            };
            var Transferedtooltip = '<b>' + this.series.name + ' : '
              + this.point.y + '</b><br><span>Transferred Dealers :</span><br>' + DealerArray;

            return Transferedtooltip;
          }
        }
      },
      series: [
        {
          name: 'NEW DEALERSHIPS',
          color: '#c19237',
          data: this.newData
        }, {
          name: 'TERMINATED DEALERSHIPS',
          color: 'grey',
          data: this.terminatedData
        },
        {
          name: 'TRANSFERRED DEALERSHIPS',
          color: 'black',
          data: this.transferredData
        }]
    });
  }

  // ====================================CERTIFICATION STATUS CHART=============================== 

  getCertificationStatsData() {
    this.certificationStatsChartLoading = true;
    let body = {
      "dealerCode": this.dealerCode,
      "region": this.selectedRegion
    }
    this.certificationNonEligibleData = 0;
    this.certificationEligibleData = 0;
    this.certificationMasterData = 0;
    this.certificationSeniorData = 0;
    this.certificationMasterJobYes = 0;
    this.certificationMasterJobNo = 0;
    this.certificationSeniorElig = 0;
    this.certificationSeniorNotElig = 0;
    this.certificationCertified = 0;
    this.certificationstatResponse = [];
    this.certNoDupe = []
    this.certEligArr = []; this.certNotEligArr = [];
    this.certEligNoDupe = []; this.certNotEligNoDupe = [];

    this.reportService.getCertificationStatData(body).subscribe((response: any) => {
      this.certEligBothCount = 0; this.certEligMasterCount = 0; this.certEligDexYesCount = 0;
      // console.log('Certification Stats', response.CertificationStatData.Result);
      this.certificationstatResponse = response.CertificationStatData.Result;
      this.certificationStatsChartLoading = false;
      this.CheckChartLoading();
      // console.log('cert chart res :: ', this.certificationstatResponse);

      if (this.certificationstatResponse.length != 0) {
        this.certificationstatResponse.forEach(element => {
          if (!this.certEligNoDupe.includes(element.SPIN_ID)) {
            this.certEligNoDupe.push(element.SPIN_ID);
            this.certNoDupe.push(element);
          }
        })
        this.certNoDupe.forEach(ele => {
          if (ele.Eligibility == 'Eligible') {
            this.certEligArr.push(ele);
          }
          if (ele.Eligibility == 'Not Eligible') {
            this.certNotEligArr.push(ele);
          }
        })
        console.log('after for each - unique ', this.certEligNoDupe);
        this.certEligArr.forEach(data => {
          if (data.cert_level == 'M' && data.eligibility_status == 'Yes') {
            this.certEligBothCount++
          } else if (data.cert_level == 'M') {
            this.certEligMasterCount++
          } else if (data.eligibility_status == 'Yes') {
            this.certEligDexYesCount++
          }
        })
        console.log('elig drill counts :: both, master, dex yes -', this.certEligBothCount, this.certEligMasterCount, this.certEligDexYesCount);
        // this.certNotEligArr.forEach(ele => {
        //   if (ele.cert_level != 'M' && ele.eligibility_status != 'Yes') {
        //   }
        // })
        if (this.certEligBothCount == 0 && this.certEligMasterCount == 0 && this.certEligDexYesCount == 0) {
          this.noCertStatData = true;
        } else { this.noCertStatData = false; }
      } else {
        this.noCertStatData = true;
      }
      this.certificationStatChart();

    }, err => {
      this.certificationNonEligibleData = 0;
      this.certificationEligibleData = 0;
      this.certificationMasterData = 0;
      this.certificationSeniorData = 0;
      this.certificationMasterJobYes = 0;
      this.certificationMasterJobNo = 0;
      this.certificationSeniorElig = 0;
      this.certificationSeniorNotElig = 0;
      this.certificationCertified = 0;
      this.noCertStatData = true;
      this.certificationStatsChartLoading = false;
      this.CheckChartLoading();
      console.log('Error while fetching data for Certification Stats Chart');
      console.log('Error', err);
      this.certificationStatChart();
    });
  }

  //Export Excel Service Call
  downloadCSFile() {
    this.certificationStatsDownloadLoading = true;
    this.downloadLoading = true;
    let body = {
      "dealerCode": this.dealerCode,
      "region": this.selectedRegion
    }
    this.downloadCertifiStatsResponse = '';
    this.downloadCSJSON = []; this.downloadEligStatNoDupe = []; this.downloadEligStat = [];
    this.reportService.downloadCertificationStatsData(body).subscribe((response: any) => {
      this.downloadCertifiStatsResponse = response.CertificationStatData.Result;
      // console.log('Download', this.downloadCertifiStatsResponse);

      this.downloadCertifiStatsResponse.length == 0 ? this.noCertStatDownloadData = true : this.noCertStatDownloadData = false;
      for (let i = 0; i < this.downloadCertifiStatsResponse.length; i++) {
        let lastDate = new Date(this.downloadCertifiStatsResponse[i].AS_OF_DATE);
        let obj = {
          "Spin Id": this.downloadCertifiStatsResponse[i].SPIN_ID,
          "First Name": this.downloadCertifiStatsResponse[i].FIRST_NAME,
          "Last Name": this.downloadCertifiStatsResponse[i].LAST_NAME,
          "Job Code": this.downloadCertifiStatsResponse[i].JOB_CODE,
          "Dealer Code": this.downloadCertifiStatsResponse[i].DEALER_CODE,
          "Area Code": this.downloadCertifiStatsResponse[i].REGION,
          "Certification Level": this.downloadCertifiStatsResponse[i].CERT_LEVEL,
          "Credit-Based Eligibility": this.downloadCertifiStatsResponse[i].ELIGIBILITY_STATUS,
          "Eligibility": this.downloadCertifiStatsResponse[i].ELIGIBILITY,
          "As Of Date": (lastDate.getMonth() + 1) + '-' + lastDate.getDate() + '-' + lastDate.getFullYear(),
        }
        this.downloadCSJSON.push(obj);
      }
      console.log('the json ', this.downloadCSJSON);
      this.downloadCSJSON.forEach(data => {
        if (!this.downloadEligStatNoDupe.includes(data["Spin Id"])) {
          this.downloadEligStatNoDupe.push(data["Spin Id"]);
          this.downloadEligStat.push(data);
        }
      })
      console.log('after dupe check :: ', this.downloadEligStat);
      this.certificationStatsDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.noCertStatDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadEligStat, 'Certification Stats');
      } else {
        this.showDownloadErrorModal();
      }
    }, error => {
      this.closeLoadingModal();
      this.showDownloadErrorModal();
      this.downloadLoading = false;
      this.certificationStatsDownloadLoading = false;
      console.log('Error while fetching export data for Certification Stats Chart');
      console.log('Error', error);
    });
  }

  certificationStatChart() {
    Highcharts.chart(this.certificationChart.nativeElement, {
      chart: {
        type: 'pie',
        height: 300,
        events: {
          drilldown: function (e) {
            let eligCat = ['Master Certified', 'Credit-Wise Eligibility', 'Both'];
            // let notEligCat = ['Master & No Eligible Primary JobCode', 'Senior Certified & No Eligible Primary JobCode', 'Foundation']
            if (e.seriesOptions.name == 'Eligible but Not Enrolled') {
              this.xAxis[0].setCategories(eligCat);
            } else if (e.seriesOptions.name == 'Not Eligible') {
              // this.xAxis[0].setCategories(notEligCat);
            }
          },
          drillup: function (e) {
            this.xAxis[0].update({ lineWidth: 0 });
          }
        }
      },
      title: {
        text: 'ELIGIBILITY STATS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'No. of associates eligible for lease program',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px',
        }
      },
      plotOptions: {
        pie: {
          startAngle: 130,
          endAngle: 130,
          size: '80%',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            distance: 0,
            color: 'black',
            format: '{point.name}'
          }
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            },
            color: 'black',
            verticalAlign: 'top'
          }
        }
      },
      legend: {
        enabled: true,
        symbolRadius: 0
      },

      credits: {
        enabled: false
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },

      yAxis: {
        title: {
          text: ''
        },
      },

      xAxis: {
        type: 'category'
      },
      series: [
        {
          name: "Eligibility Stats",
          data: [
            {
              name: "Eligible but Not Enrolled",
              color: '#C19237',
              y: this.certEligArr.length,
              drilldown: "Eligible but Not Enrolled"
            },
            {
              name: "Not Eligible",
              color: 'black',
              y: this.certNotEligArr.length,
              // drilldown: "Not Eligible"
            }
          ]
        }
      ],
      drilldown: {
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0
          }
        },
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        series: [
          {
            type: 'column',
            name: "Eligible but Not Enrolled",
            id: "Eligible but Not Enrolled",
            data: [{
              name: "Master Certified",
              y: this.certEligMasterCount,
            }, {
              name: "Credit-Wise Eligibility",
              y: this.certEligDexYesCount,
            }, {
              name: "Both",
              y: this.certEligBothCount,
            }]
          },
          // {
          //   type: 'column',
          //   name: "Not Eligible",
          //   id: "Not Eligible",
          //   data: [{
          //     name: "Master & No Eligible Primary JobCode",
          //     y: this.certificationMasterJobNo,
          //   }, {
          //     name: "Senior Certified & No Eligible Primary JobCode",
          //     y: this.certificationSeniorNotElig,
          //   }, {
          //     name: "Foundation",
          //     y: this.certificationCertified,
          //   }
          //   ]
          // }
        ]
      }
    })

    //* Expand certification stats Chart
    Highcharts.chart(this.expandcertificationChart.nativeElement, {
      chart: {
        type: 'pie',
        height: 500,
        events: {
          drilldown: function (e) {
            let eligCat = ['Master Certified', 'Credit-Wise Eligibility', 'Both'];
            // let notEligCat = ['Master & No Eligible Primary JobCode', 'Senior Certified & No Eligible Primary JobCode', 'Foundation']
            if (e.seriesOptions.name == 'Eligible but Not Enrolled') {
              this.xAxis[0].setCategories(eligCat);
            } else if (e.seriesOptions.name == 'Not Eligible') {
              // this.xAxis[0].setCategories(notEligCat);
            }
          },
          drillup: function (e) {
            this.xAxis[0].update({ lineWidth: 0 });
          }
        }
      },
      title: {
        text: 'ELIGIBILITY STATS',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: 'No. of associates eligible for lease program',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px',
        }
      },
      plotOptions: {
        pie: {
          startAngle: 130,
          endAngle: 130,
          size: '80%',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            distance: 0,
            color: 'black',
            format: '{point.name}'
          }
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            },
            color: 'black',
            verticalAlign: 'top'
          }
        }
      },
      legend: {
        enabled: true,
        symbolRadius: 0
      },

      credits: {
        enabled: false
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },

      yAxis: {
        title: {
          text: ''
        },
      },

      series: [
        {
          name: "Eligibility Stats",
          data: [
            {
              name: "Eligible but Not Enrolled",
              color: '#C19237',
              y: this.certEligArr.length,
              drilldown: "Eligible but Not Enrolled"
            },
            {
              name: "Not Eligible",
              color: 'black',
              y: this.certNotEligArr.length,
              // drilldown: "Not Eligible"
            }
          ]
        }
      ],
      drilldown: {
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0
          }
        },
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        series: [
          {
            type: 'column',
            name: "Eligible but Not Enrolled",
            id: "Eligible but Not Enrolled",
            data: [{
              name: "Master Certified",
              y: this.certEligMasterCount,
            }, {
              name: "Credit-Wise Eligibility",
              y: this.certEligDexYesCount,
            }, {
              name: "Both",
              y: this.certEligBothCount,
            }]
          },
          // {
          //   type: 'column',
          //   name: "Not Eligible",
          //   id: "Not Eligible",
          //   data: [{
          //     name: "Master & No Eligible Primary JobCode",
          //     y: this.certificationMasterJobNo,
          //   }, {
          //     name: "Senior Certified & No Eligible Primary JobCode",
          //     y: this.certificationSeniorNotElig,
          //   }, {
          //     name: "Foundation",
          //     y: this.certificationCertified,
          //   }
          //   ]
          // }
        ]
      }
    })
  }

  // ====================================VEHICLE LEASE CHART=========================================  
  changefilterPeriod(period) {
    this.vehicleLeaseData = period;
    this.filterPeriod = '';
    var currentdate = new Date();
    if (period == '1') {
      // Period is to get the last month date
      var Period = currentdate.setMonth(currentdate.getMonth() - 1);
      // filterPeriod is to convert the period into ISO format
      this.filterPeriod = new Date(Period).toISOString();
      this.getVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion);
    } else if (period == '3') {
      // Period is to get the last 3 month date from current date
      var Period = currentdate.setMonth(currentdate.getMonth() - 3);
      // filterPeriod is to convert the period into ISO format
      this.filterPeriod = new Date(Period).toISOString();
      this.getVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion);
    } else if (period == '6') {
      var Period = currentdate.setMonth(currentdate.getMonth() - 6);
      this.filterPeriod = new Date(Period).toISOString();
      this.getVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion);
    } else if (period == '365') {
      var Period = currentdate.setFullYear(currentdate.getFullYear() - 1);
      this.filterPeriod = new Date(Period).toISOString();
      this.getVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion);
    } else if (period == 'ALL') {
      this.filterPeriod = '';
      this.getVehicleLeasedData('', this.dealerCode, this.selectedRegion);
    } else if (period == 'ACTIVE') {
      this.filterPeriod = 'ACTIVE';
      this.getVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion);
    }
  }

  // this method is to get the vehicle leased data for the selected period, dealer code and region
  getVehicleLeasedData(period, dealerCode, region) {
    this.vehicleChartLoading = true;
    this.vehicleLeasedResponse = '';
    this.mainData = [];
    this.drilldownData = [];
    this.reportService.getVehicleLeasedReportData(period, dealerCode, region).subscribe((response: any) => {
      // console.log('Vehicle Leased', response.VehicleLeased.Result);
      this.vehicleLeasedResponse = response.VehicleLeased.Result;
      this.vehicleChartLoading = false;
      this.CheckChartLoading();
      if (this.vehicleLeasedResponse.length == 0) {
        this.noVehicleData = true;
      } else {
        this.noVehicleData = false;
        let colors = ['grey', 'black', 'darkgrey', '#C19237', '#C0C0C0', '#282828'];
        var finalArry = [], count = 0;
        let objData = {};

        //This For Loop is to get the colors into an array with the same length as the response which has empty model_name
        for (let i = 0; i < this.vehicleLeasedResponse.length; i++) {
          if (this.vehicleLeasedResponse[i].Model_Name == '') {
            finalArry.push(colors[count]);
            count++;
            if (count == colors.length - 1) {
              count = 0
            }
          }
        }

        //This for Loop is to seperate the total count of particular Vehicle Model
        for (let j = 0; j < this.vehicleLeasedResponse.length; j++) {
          if (this.vehicleLeasedResponse[j].Model_Name == '') {
            this.vehicleLeasedCategory.push(this.vehicleLeasedResponse[j].Vehicle_Model);
            objData = {
              "name": this.vehicleLeasedResponse[j].Vehicle_Model,
              "color": finalArry[j],
              "y": this.vehicleLeasedResponse[j].Count,
              "drilldown": this.vehicleLeasedResponse[j].Vehicle_Model
            }
            this.mainData.push(objData);
            var modelData = [];

            //This for Loop is to get the count and name of the Vehicle Model
            for (let i = 0; i < this.vehicleLeasedResponse.length; i++) {
              if (this.vehicleLeasedResponse[i].Model_Name != '' && (this.vehicleLeasedResponse[i].Vehicle_Model == this.vehicleLeasedResponse[j].Vehicle_Model)) {
                let obj = {
                  "name": this.vehicleLeasedResponse[i].Model_Name,
                  "y": this.vehicleLeasedResponse[i].Model_Count,
                }
                modelData.push(obj);
              }
            }
            let drillData = {
              "type": 'pie',
              "id": this.vehicleLeasedResponse[j].Vehicle_Model,
              "name": this.vehicleLeasedResponse[j].Vehicle_Model + ' Models',
              "data": modelData
            }
            this.drilldownData.push(drillData);
          }
        };
        this.vehiclesleased();


      }
    }, error => {
      this.vehicleLeasedResponse = '';
      this.mainData = [];
      this.drilldownData = [];
      this.noVehicleData = false;
      this.vehicleChartLoading = false;
      this.CheckChartLoading();
      this.vehiclesleased();
      console.log('Some Error occured while fetching data for Vehicle Leased Report Data');
      console.log('Error =>', error);
    });
  }

  // downloadVLReport method is to get the vehicle leased details to download in excel format

  downloadVLReport() {
    //Download Excel Service Call
    this.downloadVLResponse = [];
    this.downloadLoading = true;
    this.vehicleDownloadLoading = true;
    this.reportService.downloadVehicleLeasedData(this.filterPeriod, this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      this.downloadVLResponse = response.VehicleLeased.Result;
      // console.log(this.downloadVLResponse);
      this.vehicleDownloadLoading = false;
      if (this.downloadVLResponse.length == 0) {
        this.noVehicleDownloadData = true;
      } else {
        this.noVehicleDownloadData = false;
      }
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.vehicleDownloadLoading && !this.noVehicleDownloadData) {
        this.excelService.exportAsExcelFile(this.downloadVLResponse, 'Vehicle Leased');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.closeLoadingModal();
      this.vehicleDownloadLoading = false;
      this.noVehicleDownloadData = true;
      this.downloadLoading = false;
      this.downloadVLResponse = [];
      this.showDownloadErrorModal();
      console.log('Some Error occured while fetching data for Vehicle Leased Excel Download Report');
      console.log('Error =>', err);
    });
  }

  // *  vehiclesleased method is construct the vehicle lease chart for normal
  // *and expand mode

  vehiclesleased() {

    Highcharts.chart(this.vehiclesleasedHighchart.nativeElement, {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'VEHICLES LEASED',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 60
      },
      subtitle: {
        text: 'Lease trend based on vehicle models',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}'
          },
        },
        pie: {
          startAngle: 130,
          endAngle: 130,
          showInLegend: true,
          dataLabels: {
            enabled: true,
            color: 'grey'
          }
        },
        bar: {
          showInLegend: false,
        }
      },
      legend: {
        enabled: true,
        symbolRadius: 0
      },
      credits: {
        enabled: false
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">Vehicle Leased</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },

      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: "No. of units leased",
        },
        labels: {
          enabled: false,
        },
        lineColor: 'grey',
      },

      "series": [
        {
          "name": "Vehicle Leased",
          "data": this.mainData
        }
      ],
      "drilldown": {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        "series": this.drilldownData
      }
    })

    //* Expand vehicle leased Chart
    Highcharts.chart(this.expandvehicleLeasedChart.nativeElement, {
      chart: {
        type: 'bar',
        height: 370
      },
      title: {
        text: 'VEHICLES LEASED',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 60
      },
      subtitle: {
        text: 'Lease trend based on Lexus car models',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}'
          },
        },
        pie: {
          startAngle: 130,
          endAngle: 130,
          showInLegend: true,
          dataLabels: {
            enabled: true,
            color: 'black'
          }
        },
        bar: {
          showInLegend: false,
        }
      },
      legend: {
        enabled: true,
        symbolRadius: 0
      },
      credits: {
        enabled: false
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">Vehicle Leased</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },

      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: "No. of units leased",
        },
        labels: {
          enabled: false,
        },
        lineColor: 'black',
      },

      "series": [
        {
          "name": "Vehicle Leased",
          "data": this.mainData
        }
      ],
      "drilldown": {
        activeDataLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        activeAxisLabelStyle: {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'underline'
        },
        "series": this.drilldownData
      }
    })
  };

  // ====================================ENROLL BY JOB ROLL CHART===========================================

  JRChartOrder(e) {
    if (e.checked) {
    this.jobRoleData.sort((a, b) => (a.y > b.y ? -1 : 1));
    console.log('true, sorted by count',this.jobRoleData);
    this.jobRollChart();
    }
    if (!e.checked) {
      this.jobRoleData.sort((a, b) => (a.name < b.name ? -1 : 1));
    console.log('false, sorted by name',this.jobRoleData);
    this.jobRollChart();
    }
  }

  getJobDescriptionData() {
    this.jobRoleChartLoading = true;
    this.jobRoleChartResponse = '';
    this.jobRoleData = [];
    this.reportService.getjobRoleReportData(this.dealerCode, this.selectedRegion).subscribe((response: any) => {
      console.log('job role response', response);
      this.jobRoleChartResponse = response.JobRole.Result;
      let jobRoleDrill = response.JobRoleDrill.Result2;
      const jobRoleNewDrill = Array.from({ length: this.jobRoleChartResponse.length }, (_v, _i) => []); console.log('jobRoleNewDrill avant', jobRoleNewDrill);
      this.jobRoleChartLoading = false;
      this.CheckChartLoading();
      this.jobRoleChartResponse.length == 0 ? this.nojobRoleData = true : this.nojobRoleData = false;

      let colors = ['grey', 'black', 'darkgrey', '#C19237', '#C0C0C0', '#282828'];
      let finalArry = [], count = 0;
      for (let i = 0; i < this.jobRoleChartResponse.length; i++) {
        finalArry.push(colors[count]);
        count++;
        if (count == colors.length - 1) {
          count = 0
        }
      }
      // console.log('Color', finalArry, this.jobRoleChartResponse.length);
      let drillJobDesc = []; let drillCount = []; let drillDealerCode = [];
      for (let i = 0; i < this.jobRoleChartResponse.length; i++) {
        let body = {
          'name': this.jobRoleChartResponse[i].Job_description,
          'y': this.jobRoleChartResponse[i].Count,
          'color': finalArry[i],
          'drilldown': this.jobRoleChartResponse[i].Job_description
        }
        this.jobRoleData.push(body);
      }
      console.log('jobroledata', this.jobRoleData, this.jobRoleData.length);
      if (jobRoleDrill.length != 0) {
        for (let a = 0; a < jobRoleDrill.length; a++) {
          for (let b = 0; b < this.jobRoleData.length; b++) {
            if (jobRoleDrill[a].Job_description == this.jobRoleData[b].name) {
              jobRoleNewDrill[b].push(jobRoleDrill[a]);
            }
          }
        }
        console.log('jobRoleNewDrill apres', jobRoleNewDrill);
        for (let c = 0; c < jobRoleNewDrill.length; c++) {
          for (let d = 0; d < jobRoleNewDrill[c].length; d++) {
            drillJobDesc.push(jobRoleNewDrill[c][d].Job_description);
            drillCount.push(jobRoleNewDrill[c][d].Count);
            drillDealerCode.push(jobRoleNewDrill[c][d].DLR_CODE);
          }
          let dummy = [];
          for (let e = 0; e < drillDealerCode.length; e++) {
            dummy.push([drillDealerCode[e], drillCount[e]]);
          }
          this.jobRoleDrillFinal.push({ name: this.jobRoleData[c].name, id: this.jobRoleData[c].name, data: dummy, type: 'column' });
          drillJobDesc = []; drillCount = []; drillDealerCode = [];
          //console.log('eq or not',this.jobRoleDrillFinal.length,this.jobRoleData.length,this.jobRoleDrillFinal.length == this.jobRoleData.length);
          if (this.jobRoleDrillFinal.length == this.jobRoleData.length) {
            console.log('eq or not inside', this.jobRoleDrillFinal.length, this.jobRoleData.length, this.jobRoleDrillFinal.length == this.jobRoleData.length);
            console.log('job role drill final', this.jobRoleDrillFinal);
          }
        }
      }
      this.jobRollChart();

    }, error => {
      this.jobRoleChartResponse = '';
      this.jobRoleData = [];
      this.jobRoleChartLoading = false;
      this.CheckChartLoading();
      this.jobRollChart();
      console.log('Error while fetching data for Job role Report Chart');
      console.log('Error', error);
    });
  }

  jobRollChart() {
    let jobRoleDrillFinal = this.jobRoleDrillFinal;
    this.jobRollEnrollChart = new Chart({
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        events: {
          drilldown: function (e) {
            this.legend.update({ enabled: false});
            console.log('pt name', e.point.name);
            let series; let newCat = []; let ttl = 0; let ptName = e.point.name;
            for (let z = 0; z < jobRoleDrillFinal.length; z++) {
              if (e.point.name == jobRoleDrillFinal[z].name) {
                series = jobRoleDrillFinal[z]; console.log('series', series);
                newCat = []; ttl = 0;
                for (let y = 0; y < jobRoleDrillFinal[z].data.length; y++) {
                  newCat.push(jobRoleDrillFinal[z].data[y][0]);
                  ttl += jobRoleDrillFinal[z].data[y][1];
                }
                console.log('new cat', newCat); console.log('ttl', ttl);
              }
            }
            this.setTitle({text: 'ENROLLED BY JOB ROLE - ' + ptName + ': ' + ttl});
            this.xAxis[0].update({ title: { text: 'Dealers' } });
            this.yAxis[0].update({ title: { text: 'No. of Associates' } });
            this.xAxis[0].setCategories(newCat);
            this.addSeriesAsDrilldown(e.point, series); 
            let cont = this.container.getBoundingClientRect();
            this.setSize(cont.width, cont.height);           
          },
          drillup: function (e) {
            this.legend.update({ enabled: true});
            this.legend.update({ width: 250 });
            this.legend.update({ itemWidth: 300});
            this.setTitle({text: 'ENROLLED BY JOB ROLE'});
            this.xAxis[0].update({ title: { text: '' } });
            this.yAxis[0].update({ title: { text: '' } });
            this.xAxis[0].update({ lineWidth: 0 });
            this.yAxis[0].update({ lineWidth: 0 });
            let cont = this.container.getBoundingClientRect();
            this.setSize(cont.width, cont.height);
          }
        }
      },
      yAxis: {
        allowDecimals: false
      },
      title: {
        text: 'ENROLLED BY JOB ROLE',
        align: 'left',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
      },
      subtitle: {
        text: 'No. Of Associates Enrolled in Different Job Roles',
        align: 'left',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        x: 0,
        y: 30,
        margin: 14,
        padding: 18,
        layout: 'horizontal',
        maxHeight: 300,
        width: 250,
        itemStyle: {
          fontSize: '11px'
        },
        labelFormatter: function () {
          return this.name + ': ' + this.y;
        },
        itemDistance: 25,
        itemMarginTop: 2,
        itemWidth: 300
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span>{point.name}</span>: <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          slicedOffset: 50,
          innerSize: '60%',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.point.name;
            },
            style: { fontSize: '8px' }
          },
          point: {
            events: {
              legendItemClick: function (e) {
                console.log('e in legend', e);
                if (e.target["drilldown"] != undefined) {
                  e.target["hcEvents"].click[0]();
                } else {
                  return false;
                }
              }
            }
          }
        },
        column: {
          dataLabels: {
            enabled: true,
            color: 'black',
            formatter: function () {
              return this.y;
            },
            style: {
              fontSize: '8px',
              fontWeight: 'bold'
            }
          },
          animation: false
        }
      },
      series: [{
        name: 'Enrolled by Job role',
        data: this.jobRoleData
      }],
      drilldown: {
        series: [],
        animation: false,
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
          }
        }
      }
    });
  };

  // ====================================EXPAND CHART=======================================================  

  expand(key) {
    this.expandKey = key;
    if (key == 'trend') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.chart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'area') {
      this.showAreaMap = true;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = false;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'staffPay') {
      this.showStaff = true;
      this.showAreaMap = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = false;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'lfsExpiry') {
      this.showStaff = false;
      this.showAreaMap = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.lfsExpireChart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'certification') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = true;
      this.showVehicleLeased = false;
      this.showAngularChart = false;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'topPaid') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.topPaidChart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'holdResume') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.holdResumeChart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'dealerEnroll') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = false;
      this.showDealerEnrollmentChart = true;
    }
    else if (key == 'associateEnroll') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.associateEnrollChart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'buySell') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.buySellChart;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'vehicleLease') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = true;
      this.showAngularChart = false;
      this.showDealerEnrollmentChart = false;
    }
    else if (key == 'jobRoll') {
      this.showAreaMap = false;
      this.showStaff = false;
      this.showCertifStats = false;
      this.showVehicleLeased = false;
      this.showAngularChart = true;
      this.modalChart = this.jobRollEnrollChart;
      this.showDealerEnrollmentChart = false;
    }
  }

  // ====================================COLLAPSE CHART=========================================    
  collapse() {
    if (this.expandKey == 'trend') {
      this.trendChart();
    }
    else if (this.expandKey == "topPaid") {
      this.topPaidDealerShipChart();
    }
    else if (this.expandKey == "holdResume") {
      this.onHoldResumeChart();
    }
    else if (this.expandKey == "lfsExpiry") {
      this.lfsExpiryChart();
    }
    else if (this.expandKey == "dealerEnroll") {
      this.dealerEnrollmentChart();
    }
    else if (this.expandKey == "associateEnroll") {
      this.associateEnrollmentChart();
    }
    else if (this.expandKey == "buySell") {
      this.buySellReportChart();
    }
    else if (this.expandKey == "vehicleLease") {
      this.vehiclesleased();
    }
    else if (this.expandKey == 'jobRoll') {
      this.jobRollChart();
    }
  }

  // ====================================EXPORT TO EXCEL=========================================

  enableDropdown() {
    if (this.dropdown == true) {
      this.dropdown = false;
    } else {
      this.dropdown = true;
    }
  }

  exportFile(name, filter) {
    this.lfsFilterData = filter;
    this.showLoadingModal();
    if (name == 'Certification Stats') {
      this.downloadCSFile();
    } else if (name == 'Vehicle Leased') {
      this.downloadVLReport();
    } else if (name == 'Associate Enrollment') {
      this.downloadAEReport();
    }
    else if (name == 'Enrolled By Job Role') {
      if (!this.jobRoleChartLoading && !this.nojobRoleData) {
        this.excelService.exportAsExcelFile(this.jobRoleChartResponse, 'Enrolled By Job Role');
        this.closeLoadingModal();
      } else {
        this.closeLoadingModal();
        this.showDownloadErrorModal();
      }
    } else if (name == 'Area Wise Payment') {
      this.downloadAWReport();
    } else if (name == 'DealerShip Enrollment') {
      this.downloadDEReport();
    } else if (name == 'Buy Sell Report') {
      this.downloadBSReport();
    } else if (name == 'On-Hold\\Resume Payment') {
      this.downloadHRReport();
    } else if (name == 'Top Paid Dealership') {
      this.downloadTPReport();
    } else if (name == 'Staff Payment') {
      this.downloadSPReport();
    } else if (name == 'Lfs Expiry') {
      this.dropdown = false;
      this.downloadLEReport();
    } else if (name == 'Trend') {
      this.downloadTrendReport();
    } else if (name == 'Staff Master Data') {
      this.staffMasterDownload();
    }
  }

  showDownloadErrorModal() {
    $("#errorExcelModal").removeClass('hide_block');
    $("#errorExcelModal").addClass('display_block');
    $("#errorExcelModal").show("slow");
  }

  showErrorChartModal() {
    $("#errorChartModal").removeClass('hide_block');
    $("#errorChartModal").addClass('display_block');
    $("#errorChartModal").show("slow");
  }

  closeDownloadErrorModal() {
    $("#errorExcelModal").removeClass('display_block');
    $("#errorExcelModal").addClass('hide_block');
    $("#errorExcelModal").show("slow");
  }

  closeErrorChartModal() {
    $("#errorChartModal").removeClass('display_block');
    $("#errorChartModal").addClass('hide_block');
    $("#errorChartModal").show("slow");
  }

  showLoadingModal() {
    $("#loadingExcelModal").removeClass('hide_block');
    $("#loadingExcelModal").addClass('display_block');
    $("#loadingExcelModal").show("slow");
  }

  closeLoadingModal() {
    $("#loadingExcelModal").removeClass('display_block');
    $("#loadingExcelModal").addClass('hide_block');
    $("#loadingExcelModal").show("slow");
  }

  staffMasterDownload() {
    console.log("Inside the download fn")

    if (this.appService.isAreaAdmin) {
      this.selectedRegionSt = this.selectedRegionStAr
      console.log ('selected region', this.selectedRegionStAr)
    }

    console.log('Region', this.selectedRegionSt);
    console.log('Primary Role', this.PrimaryRole);
    console.log('isPC', this.IsPC);
    console.log('isCertifiableJobRole', this.IsCertJR)

    let area = this.selectedRegionSt;
    let PR = this.PrimaryRole;
    let IsPC = this.IsPC;
    let IsCertJR = this.IsCertJR;

    this.staffDownloadResponse = [];
    this.StaffReportDownloadLoading = true;
    this.downloadLoading = true;
    this.reportService.getStaffData(area, PR, IsPC, IsCertJR).subscribe((response: any) => {
      this.staffDownloadResponse = response.StaffReportDownload.Result;
      console.log(this.staffDownloadResponse);
      this.staffDownloadResponse.length == 0 ? this.noStaffDownloadReport = true : this.noStaffDownloadReport = false;
      this.StaffReportDownloadLoading = false;
      this.downloadLoading = false;
      this.closeLoadingModal();
      if (!this.StaffReportDownloadLoading && !this.noStaffDownloadReport) {
        this.excelService.exportAsExcelFile(this.staffDownloadResponse, 'StaffMasterData');
      } else {
        this.showDownloadErrorModal();
      }
    }, err => {
      this.StaffReportDownloadLoading = false;
      this.downloadLoading = false;
      this.staffDownloadResponse = [];
      this.closeLoadingModal();
      this.noStaffDownloadReport = true;
      this.showDownloadErrorModal();
      console.log('Error while fetching Data for Staff Master Report');
      console.log('error', err);
    });

  }

}
