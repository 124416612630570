import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class AdminReportService {

  public TrendChartURL = "ISBatchReport";

  constructor(private _http: Http, private appService: CommonService) {}

  // =====================TREND DATA SERVICE CALL==============================
  getTrendData(last_update_Date): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.TrendChartURL + this.appService.functionKey;
    let body = {
        "last_Updated_Date": last_update_Date
    }
    return this._http.post(url, body, options).map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // =====================EXCEPTION HANDLER====================================
  private handleError(error: Response | any) {
    if (error.status === 500) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 400) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 409) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 406) {
      return Observable.throw(new Error(error.status));
    } 
    else{
      return Observable.throw(new Error(error.status));
    }
  }
}
