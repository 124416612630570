import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class BillingDashboardService {

  public getReportSearchDetailsURL = "LMSElevateBillingReportSearch ";
  public getAreaTrendChartURL = "LMSElevateBillingAreaTrend ";
  public getAreaDealerTrendChartURL = "LMSElevateBillingAreaDealersTrend ";
  public getDealerTrendChartURL = "LMSElevateBillingDealerTrend";
  public getJobRoleTrendChartURL = "LMSElevateBillingJobRoleTrend";
  public getDealerAttendanceURL = "LMSElevateBillingAttendanceDealers";
  public getDealerAttendanceCntURL = "LMSElevateBillingDealersAttenCnt";
  public getJobRoleTrendDealersChartURL = "LMSElevateBillingJobDealers";
  public getJobRoleAttendanceURL = "LMSElevateBillingJobAttendance";
  public getJobRoleAttendanceCntURL = "LMSElevateBillingJobAttenCnt";

  constructor(private http: Http, public appService: CommonService) { }

  getReportSearchDetails(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getReportSearchDetailsURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getAreaTrendChart(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getAreaTrendChartURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getAreaDealerTrendChart(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getAreaDealerTrendChartURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getDealerTrendChart(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDealerTrendChartURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getJobRoleTrendChart(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getJobRoleTrendChartURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getJobRoleTrendDealersChart(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getJobRoleTrendDealersChartURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getDealerAttendance(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDealerAttendanceURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getDealerAttendanceCnt(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDealerAttendanceCntURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getJobRoleAttendance(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getJobRoleAttendanceURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getJobRoleAttendanceCnt(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getJobRoleAttendanceCntURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }

}
