import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class PaymentHistoryService {

  public getPaymentHistoryDataURL = "GetPaymentHistoryDetail";
  public getPaymentHistoryDataSpinIdURL = "GetPaymentHistoryDetailwithSpinId";
  public getDebittHistoryDataSpinIdURL = "GetDebitHistoryDetailwithSpinId";

  constructor(public http: Http, private appService: CommonService) {}

  getPayHistoryData(spinID, dealerCode){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var uid = spinID.slice(3,);
    let body = {
      'dealerCode': dealerCode,
      "uid": uid
    }
    let url = this.appService.baseUrl + this.getPaymentHistoryDataURL + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getPayHistoryDataSpinId(spinID){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var uid = spinID.slice(3,);
    let body = {
      "uid": uid
    }
    let url = this.appService.baseUrl + this.getPaymentHistoryDataSpinIdURL + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getDebitHistoryDataSpinId(spinID){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var uid = spinID.slice(3,);
    let body = {
      "uid": uid
    }
    let url = this.appService.baseUrl + this.getDebittHistoryDataSpinIdURL + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ==========================ERROR HANDLING METHOD=========================================================
  private handleError(error: Response | any) {
    if (error.status === 500) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 400) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 409) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 406) {
      return Observable.throw(new Error(error.status));
    }
  }
}
