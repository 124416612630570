import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions  } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class ReportService {
  
  public getTrendDataURL = "ReportTrend";
  public getCertificationStatsURL = "CertificationStats";
  public getAssociateEnrollmentStatusURL = "AssociateEnrollmentStatus";
  public getTopPaidDataURL = "ReportTopPaidDealer";
  public getDealerShipEnrollmentURL = "ReportDealershipEnrollmentCount";
  public getHoldResumePaymentURL = "getHoldResumeReport";
  public getVehicleLeasedReportURL = "vehicleLeased";
  public getStaffPaymentURL = "getStaffPaymentReport";
  public getBuySellReportURL = "BuySellReport";
  public getDealerCodeSearchURL = "ReportDealerCodeSearch";
  public getNotificationDealerCodeSearchURL = "NotificationDealerCodeSearch";
  public getAreaWisePaymentURL = "ReportAreaWisePayment";
  public getTopPaidSummaeryDateURL = "ReportTopPaidDealerSummary";
  public getTrendPaidCountURL = "ReportTrendPaidCount";
  public getJobRoleDetailsURL = "JobRoleEnrolledDetails";
  public getLfsExpiryDetailsURL = "ReportLfsExpiryDetails"

  //Download Excel functions
  public downloadCertificationStatsURL = "DownloadCertificationStats";
  public downloadVehicleLeasedURL = "DownloadVehicleLeasedReport";
  public downloadAssociateEnrollURL = "DownloadAssociateEnrollment";
  public downloadAreaWiseURL = "DownloadAreaWiseReport";
  public downloadDealershipURL = "DownloadDealershipReport";
  public downloadTrendURL = 'DownloadTrendReport';
  public downloadTopPaidURL = 'DownloadTopPaidReport';
  public downloadBuySellURL = 'DownloadBuySellReport';
  public downloadStaffPayURL = 'DownloadStaffPayment';
  public downloadHoldResumeURL = 'DownloadHoldResumePayReport';
  public downloadStaffReportUrl = "DownloadStaffMasterReport";


  constructor(public http: Http, private appService: CommonService) {}

  // ==================================== DEALER CODE SEARCH SERVICE CALLS===================================

  getStaffData(area, PR, IsPC, IsCertJR) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.downloadStaffReportUrl + this.appService.functionKey;
    let body = {
      "area": area,
      "PR": PR,
      "isPC": IsPC,
      "certJR": IsCertJR
    }

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }


  getDealerCodeSearch(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      searchParam: dealerCode.trim(),
      region: region.trim()
    }

    let url = this.appService.baseUrl + this.getDealerCodeSearchURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getNotificationDealerCodeSearch(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      searchParam: dealerCode.trim(),
      region: region.trim()
    }

    let url = this.appService.baseUrl + this.getNotificationDealerCodeSearchURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ===================================== END OF DEALER CODE SEARCH SERVICE CALLS===========================

  // =======================TREND CHART SERVICE CALLS========================================================

  getTrendData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }
    let url = this.appService.baseUrl + this.getTrendDataURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getTrendPaidData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }
    let url = this.appService.baseUrl + this.getTrendPaidCountURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadTrendData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }
    let url = this.appService.baseUrl + this.downloadTrendURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  

  // =======================END OF TREND CHART SERVICE CALLS=================================================

  // =======================TOP PAID SERVICE CALLS==========================================================

  getTopPaid(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }
    let url = this.appService.baseUrl + this.getTopPaidDataURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getTopPaidSummary(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim()
    }
    let url = this.appService.baseUrl + this.getTopPaidSummaeryDateURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadTopPaidData(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode
    }
    console.log('DealerStr', dealerCode)
    let url = this.appService.baseUrl + this.downloadTopPaidURL + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // =======================END OF TOP PAID SERVICE CALLS====================================================

  // =======================CERTIFICATION STATUS CHART SERVICE CALLS=========================================

  getCertificationStatData(filterDetail) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": filterDetail.dealerCode.trim(),
      "region": filterDetail.region.trim()
    }

    let url = this.appService.baseUrl + this.getCertificationStatsURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadCertificationStatsData(filterDetail) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": filterDetail.dealerCode.trim(),
      "region": filterDetail.region.trim()
    }

    let url = this.appService.baseUrl + this.downloadCertificationStatsURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // =======================END OF CERTIFICATION STATUS CHART SERVICE CALLS==================================


  // ======================= ASSOCIATE ENROLLMENT STATUS CHART SERVICE CALLS=================================

  getAssociateEnrollmentData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }

    let url = this.appService.baseUrl + this.getAssociateEnrollmentStatusURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadAssociateEnrollmentData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }

    let url = this.appService.baseUrl + this.downloadAssociateEnrollURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ======================= END OF ASSOCIATE ENROLLMENT STATUS CHART SERVICE CALLS==========================

  // =======================DEALER ENROLLMENT SERVICE CALLS==================================================

  getDealerEnrollment( dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }
    let url = this.appService.baseUrl + this.getDealerShipEnrollmentURL + this.appService.functionKey;

    return this.http.post(url, body,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  DownloadDealerEnrollment(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }
    let url = this.appService.baseUrl + this.downloadDealershipURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // =======================END OF  DEALER ENROLLMENT SERVICE CALLS==========================================

  // =======================HOLD\RESUME PAYMENT CHART SERVICE CALLS==========================================

  getHoldResumeData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "DealerCode": dealerCode.trim(),
      "region": region.trim()
    }

    let url = this.appService.baseUrl + this.getHoldResumePaymentURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadHoldResumeData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }

    let url = this.appService.baseUrl + this.downloadHoldResumeURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // =======================END OF HOLD\RESUME PAYMENT CHART SERVICE CALLS===================================

  // ==================================== STAFF PAYMENT CHART SERVICE CALLS==================================

  getStaffPaymentData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }

    let url = this.appService.baseUrl + this.getStaffPaymentURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadStaffPaymentData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }

    let url = this.appService.baseUrl + this.downloadStaffPayURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ===================================== END OF STAFF PAYMENT CHART SERVICE CALLS==========================

  // ==================================== AREA WISE PAYMENT CHART SERVICE CALLS==================================

  getAreaWisePaymentData(condition, dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      month: condition,
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }
    let url = this.appService.baseUrl + this.getAreaWisePaymentURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadAreaWisePaymentData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }
    let url = this.appService.baseUrl + this.downloadAreaWiseURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ===================================== END OF AREA WISE PAYMENT CHART SERVICE CALLS==========================

  // ==================================== BUY SELL CHART SERVICE CALLS==================================

  getBuySellReportData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }
    let url = this.appService.baseUrl + this.getBuySellReportURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadBuySellReportData(dealerCode, regionCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: regionCode.trim()
    }
    let url = this.appService.baseUrl + this.downloadBuySellURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ===================================== END OF BUY SELL CHART SERVICE CALLS==========================

  // ==================================== VEHICLE LEASED CHART SERVICE CALLS=================================

  getVehicleLeasedReportData(filterPeriod, dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      filterPeriod: filterPeriod,
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }

    let url = this.appService.baseUrl + this.getVehicleLeasedReportURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  downloadVehicleLeasedData(filterPeriod, dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      filterPeriod: filterPeriod,
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }

    let url = this.appService.baseUrl + this.downloadVehicleLeasedURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ===================================== END OF VEHICLE LEASED CHART SERVICE CALLS=========================

  // ==================================== JOB ROLE CHART SERVICE CALLS==================================

  getjobRoleReportData(dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      dealerCode: dealerCode.trim(),
      region: region.trim()
    }
    let url = this.appService.baseUrl + this.getJobRoleDetailsURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ===================================== END OF JOB ROLE CHART SERVICE CALLS==========================

  // ==================================== LFS EXPIRY CHART SERVICE CALLS==================================

  getLfsExpiryReportData(searchParam, dealerCode, region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "searchParam": searchParam,
      "dealerCode": dealerCode.trim(),
      "region": region.trim()
    }
    let url = this.appService.baseUrl + this.getLfsExpiryDetailsURL + this.appService.functionKey;

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  
  // ===================================== END OF LFS EXPIRY CHART SERVICE CALLS==========================


  // ==========================ERROR HANDLING METHOD=========================================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }

}