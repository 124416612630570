import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { CommonService } from '../service/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class PaymentProcessService {

  public paymentPostURL = 'PaymentListPostedToPS';
  public insertPaymentURL = 'InsertToPaymentHistory';
  public emailInsertURL = 'SentDealershipMail';
  public notifyInsertURL = 'PaymentAcknowledgementNotification';
  public updatePaymentURL = 'UpdatePaymentList';
  public getDealerURL = 'GetDealerCode';

  constructor(private _http: Http, private appService: CommonService) { }

  // =========================== Payment List Service Call ========================================
  getPaymentList() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.paymentPostURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================ Insert Payment List =============================================
  insertPaymentList() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertPaymentURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ========================== Send Email Service Call ============================================
  emailPaymentSucess(dealerDetail) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerDetail.dealerCode,
      "dealerName": dealerDetail.dealerName
    }
    const url = this.appService.baseUrl + this.emailInsertURL + this.appService.functionKey;
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ================================ Send Notification Service Call =================================
  NotifyPaymentSucess(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode
    }
    const url = this.appService.baseUrl + this.notifyInsertURL + this.appService.functionKey;
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ================================= Update Payment List Service Call ===============================
  updatePayment() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updatePaymentURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ================================= Get Dealer List Service Call ===============================
  getDealerList() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDealerURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ===================================== Exception Handler ===========================================
  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
