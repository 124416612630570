import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class UnenrollService {

  public getEnrolledUserUrl = "HoldPaymentSearch";
  public getHoldDetailsUrl = "HoldHistoryDetails";
  public toHoldPaymentUrl = "InsertHoldPayment";
  public getActiveHoldListUrl = "GetActiveHoldList";
  public getHoldHistoryListUrl = "GetHoldHistoryList";
  public resumePaymentUrl = "ResumePayment";
  public extendPaymentUrl = "updateHoldPayment";
  public getFutureHoldListUrl = "FutureHoldList";
  public getLastPaymentDateUrl = "getLastPaymentDate";
  public getHistoryCommentUrl = "GetHoldHistoryComments";

  constructor(private http: Http, public appService : CommonService) {}

  getEnrolledUserSearch(dealerCode, select) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode,
      "searchParam": select
    }
    const url = this.appService.baseUrl + this.getEnrolledUserUrl + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getHoldDetails(dealerCode, spinId, enrollNo) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode,
      "spinId": spinId,
      "enrollmentNo": enrollNo,
    }
    const url = this.appService.baseUrl + this.getHoldDetailsUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  associateHoldPayment(userDetail) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "firstName": userDetail.firstName,
      "lastName": userDetail.lastName,
      "dealerCode": userDetail.dealerCode,
      "enrollmentNo": userDetail.enrollmentNo,
      "spinId": userDetail.spinId,
      "startPeriod": userDetail.startPeriod,
      "endPeriod": userDetail.endPeriod,
      "holdBy": userDetail.holdBy,
      "reason": userDetail.reason,
      "comments": userDetail.comments
    }
    const url = this.appService.baseUrl + this.toHoldPaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getActiveHoldList(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode
    }
    const url = this.appService.baseUrl + this.getActiveHoldListUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getHoldHistoryListDetails(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode
    }
    const url = this.appService.baseUrl + this.getHoldHistoryListUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getFutureHoldList(dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "dealerCode": dealerCode
    }

    const url = this.appService.baseUrl + this.getFutureHoldListUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  resumeAssociatePayment(paymentHoldId,holdBy) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "paymentHoldId": paymentHoldId,
      "holdBy":holdBy
    }

    const url = this.appService.baseUrl + this.resumePaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  extendAssociatePayment(details) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "endPeriod": details.endPeriod,
      "paymentHoldId": details.paymentHoldId,
      "holdBy": details.holdBy,
      "comments": details.comments
    }

    const url = this.appService.baseUrl + this.extendPaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getLastPaymentDate(spinId,enrollmentNo,dealerCode){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "spinId": spinId,
      "enrollmentNo": enrollmentNo,
      "dealerCode": dealerCode
    }

    const url = this.appService.baseUrl + this.getLastPaymentDateUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getHoldHistoryComments(paymentHoldId){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "paymentHoldId": paymentHoldId
    }

    const url = this.appService.baseUrl + this.getHistoryCommentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
