import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class LexusJobService {

  public getStaffMasterUserDtlURL = "LMSStaffMasterUserSummarySearch ";
  public getTurnOverJobCodesURL = "LMSTurnoverJobCodesFetch";
  public getTurnOverReportURL = "LMSTurnOverReportSearch";
  public getJobEnrollHistoryURL = "LMSJobIdentitySearch";
  public getTurnOverCertReportURL = "LMSTurnOverReportCertSearch";

  constructor(private http: Http, public appService: CommonService) { }

  getStaffMasterUserDtl(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getStaffMasterUserDtlURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
  getTurnOverJobCodes(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getTurnOverJobCodesURL + this.appService.functionKey;
    return this.http.get(url ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getTurnOverReport(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getTurnOverReportURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getTurnOverCertReport(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getTurnOverCertReportURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getJobEnrollHistory(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getJobEnrollHistoryURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
}
