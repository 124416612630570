import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });


@Injectable()
export class SwitchAccountService {

  public getEnrolledDealerUrl = "DealerDataSearch";
  public getdealerURL = 'getDealerBasedAssociateData';
  public updateTestUserURL = 'updateTestUser';

  constructor(private http: Http, public appService : CommonService) { }

  // ============================ DEALER DATA SEARCH =========================================
  getEnrolledDealerSearch(select,region) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var searchWord = select.split(' ');
    var searchParam1 ='';
    if(searchWord[1] != null)
      searchParam1 =searchWord[1]
    let body = {
      'searchParam': searchWord[0],
      'searchParam1': searchParam1,
      'region': region
    }
    const url = this.appService.baseUrl + this.getEnrolledDealerUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================ DEALER BASED ASSOCIATE DATA=========================================
  getDealerData(dlrcode){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getdealerURL + this.appService.functionKey;
    const body = {
      'dealerCode': dlrcode
    };

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================ DEALER BASED ASSOCIATE DATA=========================================
  updateTestUser(name,dlrcode,spinid){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateTestUserURL + this.appService.functionKey;
    const body = {
      'name': name,
      'dealerCode': dlrcode,
      'spinid': spinid
    };

    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================ EXCEPTION HANDLER =========================================
  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
